export interface HighlightedWord {
  text: string;
  bold: boolean;
}

export function highlightKeywords(title: string, keywords: string): HighlightedWord[] {
  const result: HighlightedWord[] = [];

  // Split keywords by space and remove leading/trailing special characters
  const keywordArray = keywords.split(' ').map((kw) => kw.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, ''));

  // Create a regular expression pattern to match keywords while respecting special characters
  const pattern = keywordArray.map((kw) => kw.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|');
  const regex = new RegExp(`\\b(${pattern})\\b`, 'gi');

  let lastIndex = 0;

  // Loop through each match to construct the result array
  title.replace(regex, (match, p1, offset) => {
    // Add the non-bolded text before the match
    if (offset > lastIndex) {
      result.push({ text: title.slice(lastIndex, offset), bold: false });
    }

    // Add the bolded match, but only if it's not an advanced search keyword
    // or if it matches exactly with the keyword (case-sensitive)
    if (
      (match.toUpperCase() !== 'AND' && match.toUpperCase() !== 'OR' && match.toUpperCase() !== 'NOT') ||
      keywordArray.includes(match)
    ) {
      result.push({ text: match, bold: true });
    } else {
      result.push({ text: match, bold: false });
    }

    lastIndex = offset + match.length;
    return match;
  });

  // Add any remaining non-bolded text
  if (lastIndex < title.length) {
    result.push({ text: title.slice(lastIndex), bold: false });
  }

  return result;
}
