import { ArticleOutlined, FormatQuote } from '@mui/icons-material';
import { Box, CardHeader, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import { Color } from '../../../lib/colors';
import { WorkEntity } from '../../../lib/excover';
import { HighlightedWord, highlightKeywords } from '../../../lib/highlight-keywords';
import { MathUtils } from '../../../utils/MathUtils';
import { StringUtils } from '../../../utils/StringUtils';
import { WorkUtils } from '../../../utils/WorkUtils';
import { CitationDialog } from '../../citations/CitationDialog/CitationDialog';
import { OpenAccessIcon } from '../../icons/OpenAccessIcon';

interface IProps {
  work?: WorkEntity;
  keywords?: string;
  onCitePublicationClick: () => void;
  PublicationBookmarkButtonComponent?: React.ReactNode;
}

export const PublicationHeader: React.FC<IProps> = ({
  work,
  keywords,
  onCitePublicationClick,
  PublicationBookmarkButtonComponent,
}) => {
  const [citationDialogOpen, setCitationDialogOpen] = useState(false);

  const [titleParts, setTitleParts] = useState<HighlightedWord[]>([]);

  useEffect(() => {
    if (!work || !keywords) return;
    setTitleParts(highlightKeywords(work.title, keywords));
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ArticleOutlined htmlColor={Color.Publication} fontSize="small" />
          <Typography variant="body2" color="secondary" sx={{ fontSize: 14, fontWeight: 'light', ml: 2 }}>
            {work ? WorkUtils.getReadableType(work) : <Skeleton width={MathUtils.getRandomBetween(100, 120)} />}
          </Typography>
          {work && Boolean(work.open_access.oa_url) && (
            <Tooltip title="Open Access">
              <Box component="span" sx={{ display: 'flex' }}>
                <OpenAccessIcon color="action" sx={{ ml: 2, fontSize: '1rem' }} />
              </Box>
            </Tooltip>
          )}
        </Box>
        <Box sx={{ display: 'flex', padding: '4px 2px 2px 2px' }} onClick={(event) => event.stopPropagation()}>
          <Tooltip title="Cite Publication">
            <IconButton
              onClick={() => {
                setCitationDialogOpen(true);
                onCitePublicationClick();
              }}
              size="medium"
              style={{ padding: 2 }}
              aria-label="cite publication"
            >
              <FormatQuote />
            </IconButton>
          </Tooltip>

          {PublicationBookmarkButtonComponent}
        </Box>
      </Box>

      <CardHeader
        sx={{ p: 0 }}
        disableTypography
        title={
          <Typography style={{ lineHeight: 1.2 }} className="ph-no-capture">
            {work ? (
              titleParts.length ? (
                titleParts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.bold ? 700 : 400 }}
                    dangerouslySetInnerHTML={{
                      __html: StringUtils.removeNonGlyphCharacters(StringUtils.sanitize(part.text)),
                    }}
                  />
                ))
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: StringUtils.removeNonGlyphCharacters(StringUtils.sanitize(work.title)),
                  }}
                />
              )
            ) : (
              <Skeleton height={39} sx={{ transform: 'scale(1)' }} />
            )}
          </Typography>
        }
        subheader={
          <Typography
            variant="caption"
            display="block"
            color="secondary"
            sx={{ fontSize: 11 }}
            className="ph-no-capture"
          >
            {work ? (
              <>
                <strong>{format(new Date(work.publication_date), 'PPP')}</strong>
              </>
            ) : (
              <Skeleton width={MathUtils.getRandomBetween(60, 90)} />
            )}
          </Typography>
        }
      />

      {work && citationDialogOpen && (
        <CitationDialog works={[work]} open={citationDialogOpen} onClose={() => setCitationDialogOpen(false)} />
      )}
    </>
  );
};
