import { Lightbulb } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';

import { Color } from '../../../lib/colors';
import { StringUtils } from '../../../utils/StringUtils';

export enum TopicChipState {
  Included = 'included',
  Excluded = 'excluded',
  Suggested = 'suggested',
}

export interface TopicChipProps extends ChipProps {
  state?: TopicChipState;
  fitToLength?: number;
}

export const TopicChip: React.FC<TopicChipProps> = ({ state, fitToLength, ...props }) => {
  const label = typeof props.label === 'string' ? StringUtils.fitToLength(props.label, 24) : props.label;
  const variant = state === TopicChipState.Included || state === TopicChipState.Excluded ? 'filled' : 'outlined';

  return (
    <Chip
      className="ph-no-capture"
      variant={variant}
      size="small"
      {...props}
      label={
        StringUtils.isString(label) ? (
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            dangerouslySetInnerHTML={{
              __html: StringUtils.fitToLength(
                StringUtils.removeNonGlyphCharacters(StringUtils.sanitize(label as string)),
                fitToLength,
              ),
            }}
          />
        ) : (
          label
        )
      }
      icon={
        <Lightbulb
          sx={{
            ...(variant === 'outlined' && { color: `${Color.Journal} !important` }),
            ...(variant === 'filled' && { color: `${Color.White} !important` }),
            color: `${state === TopicChipState.Suggested ? Color.Topic : 'inherit'} !important`,
          }}
        />
      }
      sx={{
        maxWidth: '100%',
        ...props.sx,
        ...(props.size === 'small' && { fontSize: 12, height: 'auto' }),
        ...(props.size === 'medium' && { fontSize: 16, height: 'auto' }),
        ...(variant === 'outlined' && { background: Color.White }),
        ...(variant === 'filled' && { background: `${Color.Topic} !important`, color: Color.White }),
        ...(state === TopicChipState.Excluded && { textDecoration: 'line-through' }),
        ...(state === TopicChipState.Suggested && { background: Color.White }),
      }}
    />
  );
};
