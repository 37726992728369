import { v4 as uuid } from 'uuid';
import { create } from 'zustand';

import { AlertMessageProps } from '../components/alertMessages/AlertMessage/AlertMessage';

type AlertsStore = {
  alerts: AlertMessageProps[];

  createAlert: (message: Partial<AlertMessageProps>) => void;
  closeAlert: (id: string) => void;
  closeAllAlerts: () => void;
};

export const useAlertsStore = create<AlertsStore>((set, get) => ({
  alerts: [],

  createAlert: (message) => {
    const content = message.content || message.children;
    if (get().alerts.some((m) => (m.content || m.children) === content)) {
      return;
    }
    set({ alerts: [...get().alerts, { id: uuid(), content: '', severity: 'error', ...message }] });
  },

  closeAlert: (id) => {
    const index = get().alerts.findIndex((message) => message.id === id);
    if (index === -1) return;
    set({ alerts: get().alerts.filter((_, i) => i !== index) });
  },

  closeAllAlerts: () => {
    set({ alerts: [] });
  },
}));
