import { axios } from './axios';
import { config } from './config';
import { ExcoverQueryDto, ExcoverTopicsSearchDto, ExcoverTopicsSearchEntity } from './excover';
import { SearchEntity } from './types';

export class API {
  static Search = {
    /**
     * Recommend articles
     * @param query - search query
     * @returns results
     */
    async search(query: ExcoverQueryDto) {
      return await axios.post<SearchEntity>(`${config.API_URL}/api/search`, query);
    },

    /**
     * Recommend topics
     * @param query - search query
     * @returns results
     */
    async searchTopics(query: ExcoverTopicsSearchDto) {
      return await axios.post<ExcoverTopicsSearchEntity>(`${config.API_URL}/api/topics/search`, query);
    },
  };
}
