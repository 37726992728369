import { Button, ButtonProps, darken, styled } from '@mui/material';
import { forwardRef } from 'react';

import { Color } from '../../lib/colors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CustomButtonProps extends ButtonProps<any> {
  disableGrow?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const CustomButtonFwd = forwardRef(({ disableGrow, ...props }: CustomButtonProps, ref: any) => (
  <Button color="primary" variant="contained" disableRipple={props.variant === 'text'} {...props} ref={ref} />
));
CustomButtonFwd.displayName = 'CustomButtonFwd';

export const CustomButton: React.FC<CustomButtonProps> = styled(CustomButtonFwd)(
  ({ color = 'primary', variant = 'contained', size = 'medium', fullWidth, disabled, disableGrow, theme }) => ({
    fontSize: '1rem !important',

    '&.MuiButton-root': {
      borderRadius: 90,
      transition: 'all 0.4s ease',

      ...(color === 'primary' && { borderColor: Color.Purple }),
      ...(color === 'secondary' && { borderColor: Color.DarkGrey }),
      ...(color === 'warning' && { borderColor: darken(Color.Yellow, 0.1) }),

      ...(variant === 'contained' && {
        borderWidth: '1px !important',
        borderStyle: 'solid',
        ...(color === 'primary' && { backgroundColor: Color.Purple }),
        ...(color === 'secondary' && { backgroundColor: Color.DarkGrey }),
        ...(color === 'warning' && { backgroundColor: darken(Color.Yellow, 0.1), color: Color.White }),
      }),
      ...(variant === 'outlined' && {
        backgroundColor: 'transparent',
        ...(color === 'secondary' && { borderColor: Color.Grey3 }),
        ...(color === 'warning' && { color: Color.DarkGrey }),
      }),

      ...(size === 'small' && {
        ...(!fullWidth && !disableGrow && { margin: '8px 8px' }),
        padding: '8px 16px',
      }),
      ...(size === 'medium' && {
        ...(!fullWidth && !disableGrow && { margin: '16px 16px' }),
        padding: '16px 32px',
      }),
      ...(size === 'large' && {
        ...(!fullWidth && !disableGrow && { margin: '33px 32px' }),
        padding: '30px 160px',
      }),
      ...(variant === 'text' && {
        ...(!fullWidth && !disableGrow && { margin: '4px' }),
        padding: '8px',
      }),

      '&:hover': {
        ...(variant === 'contained' && {
          ...(color === 'primary' && { backgroundColor: Color.Purple }),
          ...(color === 'secondary' && { backgroundColor: Color.DarkGrey }),
          ...(color === 'warning' && { backgroundColor: darken(Color.Yellow, 0.2) }),
        }),
        ...(variant === 'outlined' && { backgroundColor: 'transparent' }),
        ...(variant === 'text' && { backgroundColor: 'transparent' }),

        ...(size === 'small' && {
          ...(!fullWidth &&
            !disableGrow && {
              margin: '6px 4px',
              padding: '10px 20px',
            }),
        }),
        ...(size === 'medium' && {
          ...(!fullWidth &&
            !disableGrow && {
              margin: '12px 8px',
              padding: '20px 40px',
            }),
        }),
        ...(size === 'large' && {
          ...(!fullWidth &&
            !disableGrow && {
              margin: '30px 16px',
              padding: '33px 176px',
            }),
        }),
        ...(variant === 'text' && {
          ...(!fullWidth &&
            !disableGrow && {
              margin: '4px',
              padding: '8px',
            }),
        }),
      },

      ...(disabled && {
        borderColor: 'transparent',
        ...(variant !== 'text' && {
          background: theme.palette.action.disabledBackground,
        }),
        color: theme.palette.action.disabled,
      }),
    },
  }),
);
