import { create } from 'zustand';

import { ICitationStyle } from '../types/citations';
import { CitationUtils } from '../utils/CitationUtils';
import { LocalStorageUtils } from '../utils/LocalStorageUtils';

type CitationsStore = {
  selectedStyle: ICitationStyle;
  setSelectedStyle: (style: ICitationStyle) => void;
};

export const useCitationsStore = create<CitationsStore>((set) => ({
  selectedStyle:
    typeof window !== 'undefined'
      ? LocalStorageUtils.get<ICitationStyle>('citationStyle') || CitationUtils.CITATION_STYLES[0]
      : CitationUtils.CITATION_STYLES[0],

  setSelectedStyle: (style) => {
    set({ selectedStyle: style });
    LocalStorageUtils.set('citationStyle', style);
  },
}));
