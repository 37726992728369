import { Lightbulb } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';

import { TopicWithTypeEntity } from '../../../lib/excover';
import { MathUtils } from '../../../utils/MathUtils';
import { TopicChip, TopicChipState } from '../../topics/TopicChip/TopicChip';

interface IProps {
  topics?: TopicWithTypeEntity[];
  children: React.ReactNode;
}

export const PublicationTopics: React.FC<IProps> = ({ topics, children }) => {
  return (
    <Box sx={{ pb: 4 }}>
      <Box sx={{ py: 4, px: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="overline" sx={{ letterSpacing: 1.25, lineHeight: 1 }} color="secondary">
            Topics
            <Box sx={{ display: 'inline-flex', alignItems: 'center', ml: 6 }}>
              {topics ? <strong>{topics.length}</strong> : <Skeleton width={20} />}
              <Lightbulb sx={{ fontSize: 14 }} />
            </Box>
          </Typography>

          {/* <Typography
            variant="overline"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              textTransform: 'inherit',
              letterSpacing: 0,
              lineHeight: 1,
              cursor: 'pointer',
            }}
            color="secondary"
          >
            View all
            <ViewAgenda sx={{ fontSize: 14, ml: 2 }} />
          </Typography> */}
        </Box>
      </Box>

      <Box sx={{ px: 2 }}>
        {topics
          ? children
          : [...Array(10)].map((item, index) => (
              <TopicChip
                key={index}
                state={TopicChipState.Suggested}
                label={<Skeleton width={MathUtils.getRandomBetween(70, 120)} />}
                size="small"
                sx={{ m: 1, pl: 1 }}
              />
            ))}
      </Box>
    </Box>
  );
};
