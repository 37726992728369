export enum EntityTab {
  Articles = 0,
  Topics = 1,
}

export enum EntityEnum {
  Publication = 'publication',
  Author = 'author',
  Journal = 'journal',
  Topic = 'topic',
  Institution = 'institution',
  Generic = 'generic',
}

export interface EntityProps {
  id: string;
  type: EntityEnum;
  title: string;
  visible?: boolean;
  focused?: boolean;
  zIndex?: number;
  withSidebar?: boolean;
  getTemplate?: () => JSX.Element;
}
