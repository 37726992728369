import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export function useScrolledTo({ onScroll }: { onScroll: () => void }) {
  const { ref, inView } = useInView({ threshold: 0.4 });
  const [alreadyScrolledDown, setAlreadyScrolledDown] = useState(false);

  useEffect(() => {
    if (!inView || alreadyScrolledDown) return;
    onScroll && onScroll();
    setAlreadyScrolledDown(true);
  }, [inView]);

  return ref;
}
