import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { axios } from '../../../lib/axios';
import { BookmarkEntity } from '../../../lib/types';

/**
 * Send a request to delete a bookmark
 * @param bookmark - The bookmark to delete
 * @returns The deleted bookmark
 */
async function deleteBookmark(bookmark: BookmarkEntity) {
  const { data } = await axios.delete<BookmarkEntity>(`/api/bookmarks/${bookmark.id}`);
  return data;
}

/**
 * Delete a bookmark
 * @returns Bookmark delete mutation
 */
export function useDeleteBookmark() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteBookmark,
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['bookmarks'] });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });
}
