import { Box, styled } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { HelpButton } from '@keenious/libraries/components/common/HelpButton';
import { PrivacyNoticeDialog } from '@keenious/libraries/components/search/PrivacyNoticeDialog/PrivacyNoticeDialog';
import { useGetBookmarks } from '@keenious/libraries/hooks/data/bookmarks/useGetBookmarks';
import { useAuth } from '@keenious/libraries/hooks/features/useAuth';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { config } from '@keenious/libraries/lib/config';
import { AddonNavigation } from '@keenious/libraries/lib/navigation';
import { useEntitiesStore } from '@keenious/libraries/store/useEntitiesStore';
import { useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useUiStore } from '@keenious/libraries/store/useUiStore';
import { LocalStorageUtils } from '@keenious/libraries/utils/LocalStorageUtils';

import { Environment, environment } from '../../../lib/environment';
import { useSearchStore } from '../../../store/useSearchStore';
import { Entities } from '../../entities/Entities';
import { Editor } from '../Editor/Editor';
import { PermanentSidebar } from '../PermanentSidebar/PermanentSidebar';

export const PageBackground = styled('div')({
  backgroundImage: `url(${config.WEBSITE_URL}/images/landing/features/background.webp)`,
  backgroundSize: 'contain',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  opacity: 0.5,
  zIndex: -1,
});

export function Layout({ children }: { children: React.ReactNode }) {
  const { user, institution, isPlusUser } = useAuth();

  const privacyNoticeDialogOpen = useUiStore((state) => state.privacyNoticeDialogOpen);
  const togglePrivacyNoticeDialog = useUiStore((state) => state.togglePrivacyNoticeDialog);
  const hasVisibleEntities = useEntitiesStore((state) => state.hasVisibleEntities);
  const pagination = useFiltersStore((state) => state.pagination);
  const setPagination = useFiltersStore((state) => state.setPagination);
  const applySearch = useSearchStore((state) => state.applySearch);

  useGetBookmarks();

  const mounted = useRef(false);
  const navigate = useNavigate();

  // Identify the user in PostHog
  useEffect(() => {
    AnalyticsUtils.identifyUser(user, institution, isPlusUser);
  }, [user, institution, isPlusUser]);

  // Set the default pagination limit to 50 for Plus users and 10 for non-Plus users
  useEffect(() => {
    if (mounted.current || !isPlusUser) return;

    setPagination({ ...pagination, limit: isPlusUser ? 50 : 10 });

    mounted.current = true;
  }, [isPlusUser]);

  const privacyNoticeAccepted = LocalStorageUtils.get('privacyNoticeAccepted');

  return (
    <Box sx={{ display: 'flex' }}>
      {environment === Environment.Web && (
        <Box
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            overflowX: 'hidden',
            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <PageBackground />
          <Editor />
        </Box>
      )}

      <PermanentSidebar variant={environment === Environment.Web ? 'window' : 'normal'}>
        <Box
          sx={{
            display: hasVisibleEntities ? 'none' : 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            flex: 1,
          }}
        >
          {children}
        </Box>
        <Entities />
        <HelpButton />

        {!privacyNoticeAccepted && (
          <PrivacyNoticeDialog
            open={privacyNoticeDialogOpen}
            onCancel={() => {
              togglePrivacyNoticeDialog(false);
              navigate(AddonNavigation.Home);
            }}
            onContinue={() => {
              togglePrivacyNoticeDialog(false);
              LocalStorageUtils.set('privacyNoticeAccepted', true);
              applySearch();
            }}
          />
        )}
      </PermanentSidebar>
    </Box>
  );
}
