import { useQuery } from '@tanstack/react-query';
import { RawAxiosRequestConfig } from 'axios';

import { axios } from '../../../lib/axios';
import { WorkEntity } from '../../../lib/excover';
import { WorkUtils } from '../../../utils/WorkUtils';

/**
 * Send a request to get a work by its ID
 * @param workId - The work's ID
 * @param config - Axios request config
 * @returns The work data
 */
export async function getWork(workId: string, config?: RawAxiosRequestConfig) {
  const { data } = await axios.get<WorkEntity | null>(`/api/works/${workId}`, config);
  return data;
}

/**
 * Get a work by its ID
 * @returns Work query
 */
export function useGetWork(workId: string) {
  return useQuery({
    queryKey: ['works', WorkUtils.getId(workId)],
    queryFn: () => getWork(WorkUtils.getId(workId)),
    enabled: Boolean(workId),
  });
}
