import { TeamEntity, TeamSubscriptionEntity } from '../types';
import { Subscription } from './Subscription';

export class TeamSubscription extends Subscription {
  plusUserIds: string[];
  // Team
  team?: TeamEntity;
  teamId: string;

  private _userId: string;

  constructor(subscription: TeamSubscriptionEntity, userId: string) {
    super();

    // Copy all properties from the subscription object to this instance
    Object.assign(this, subscription);

    this._userId = userId;
  }

  /**
   * Check if a user has access to Plus features
   * @returns Whether the user has access to Plus features
   */
  hasPlusAccess() {
    // If the team's subscription is not active, they don't have access to Plus features
    if (!this.isActive()) return false;

    // Check if they are a Plus user
    return this.plusUserIds.includes(this._userId);
  }
}
