import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../../lib/axios';

/**
 * Send a request to sign out the user
 * @returns The user data
 */
async function signOut() {
  const { data } = await axios.post<void>(`/api/auth/sign-out`);
  return data;
}

/**
 * Create the user
 */
export function useSignOut() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: signOut,
    onMutate: () => {
      queryClient.cancelQueries();
    },
    onSuccess: () => {
      queryClient.clear();
    },
  });
}
