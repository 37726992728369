import {
  ISimilarArticlesProps,
  SimilarArticles,
} from '@keenious/libraries/components/publication/SimilarArticles/SimilarArticles';
import { useGetSimilarWorks } from '@keenious/libraries/hooks/data/works/useGetSimilarWorks';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { ExcoverQueryEntity, WorkEntity } from '@keenious/libraries/lib/excover';

import { PublicationCardContainer } from '../PublicationCardContainer';

interface IProps extends ISimilarArticlesProps {
  work?: WorkEntity;
}

export const SimilarArticlesContainer: React.FC<IProps> = ({ work, ...props }) => {
  const { data, isFetching, fetchNextPage, hasNextPage } = useGetSimilarWorks(work?.id);

  async function handleSeeMore() {
    fetchNextPage();
    AnalyticsUtils.captureEvent('Articles: Clicked "See More" on "Similar Articles"');
  }

  const hits: ExcoverQueryEntity['hits'] = data?.pages.map((page) => page.hits)?.flat();

  return (
    <SimilarArticles
      workId={work?.id}
      onScrollDown={() => AnalyticsUtils.captureEvent('Articles: Scrolled Down To "Similar Articles"')}
      hits={hits}
      onSeeMoreClick={handleSeeMore}
      loading={isFetching}
      hasMore={hasNextPage}
      {...props}
    >
      {hits?.map((hit) => (
        <PublicationCardContainer key={hit.entity.id} work={hit.entity} meta={hit.meta} disableResultInsights />
      ))}
    </SimilarArticles>
  );
};
