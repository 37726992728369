import { create } from 'zustand';

import { EntityTab } from '../types/entities';

type UiStore = {
  privacyNoticeDialogOpen: boolean;
  scrollableContainers: HTMLDivElement[];
  entityTab: EntityTab;
  loading: boolean;

  togglePrivacyNoticeDialog: (value?: boolean) => void;
  setEntityTab: (tab: EntityTab) => void;
  setLoading: (loading: boolean) => void;
  addScrollableContainer: (scrollableContainer: HTMLDivElement) => void;
  scrollToTop: () => void;
};

export const useUiStore = create<UiStore>((set, get) => ({
  privacyNoticeDialogOpen: false,
  scrollableContainers: [],
  entityTab: EntityTab.Articles,
  loading: false,

  togglePrivacyNoticeDialog: (value) => set({ privacyNoticeDialogOpen: value ?? !get().privacyNoticeDialogOpen }),
  setEntityTab: (tab) => set({ entityTab: tab }),
  setLoading: (loading) => set({ loading }),

  addScrollableContainer: (scrollableContainer) =>
    set((state) => ({ scrollableContainers: [...state.scrollableContainers, scrollableContainer] })),

  scrollToTop: () => {
    requestAnimationFrame(() => {
      get()
        .scrollableContainers.filter(Boolean)
        .forEach((scrollableContainer) => {
          scrollableContainer?.parentElement?.scrollTo({ top: 0, behavior: 'smooth' });
        });
    });
  },
}));
