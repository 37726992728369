import { create } from 'zustand';

import { EntityEnum, EntityProps } from '../types/entities';

export class Entity implements EntityProps {
  id: string;
  type: EntityEnum;
  title: string;
  visible?: boolean;
  focused?: boolean;
  zIndex?: number;
  withSidebar?: boolean;
  getTemplate?: () => JSX.Element;

  constructor(props: EntityProps) {
    this.id = props.id;
    this.type = props.type;
    this.title = props.title;
    this.visible = props.visible;
    this.focused = props.focused;
    this.zIndex = props.zIndex;
    this.withSidebar = props.withSidebar ?? true;
    this.getTemplate = props.getTemplate;
  }

  setVisible(visible: boolean) {
    this.visible = visible;
  }

  setFocused(focused: boolean) {
    this.focused = focused;
  }

  setZIndex(zIndex: number) {
    this.zIndex = zIndex;
  }
}

interface EntitiesStore {
  entities: Entity[];
  hasVisibleEntities: boolean;
  maxZIndex: () => number;
  getIndexById: (id: string) => number;
  get: (id: string) => Entity | undefined;
  isFocused: (id: string) => boolean;
  open: (props: EntityProps) => void;
  close: (id: string) => void;
  closeAll: () => void;
  bringToFront: (entity: Entity) => void;
}

export const useEntitiesStore = create<EntitiesStore>((set, get) => ({
  entities: [],
  hasVisibleEntities: false,

  maxZIndex: () => {
    return Math.max(
      ...get()
        .entities.filter((item) => item.visible)
        .map((item) => item.zIndex ?? 0),
      0,
    );
  },

  getIndexById: (id) => {
    return get().entities.findIndex((entity) => entity.id === id);
  },

  get: (id) => {
    const index = get().getIndexById(id);
    return get().entities[index];
  },

  isFocused: (id) => {
    const index = get().getIndexById(id);
    const item = get().entities[index];
    return index !== -1 && item.visible && item.zIndex === get().maxZIndex();
  },

  open: (props) => {
    const index = get().getIndexById(props.id);

    if (index !== -1) {
      get().bringToFront(get().entities[index]);
      return;
    }

    const newEntity = new Entity({
      visible: false,
      focused: true,
      zIndex: get().maxZIndex() + 1,
      ...props,
    });

    set((state) => ({ entities: [...state.entities, newEntity] }));

    setTimeout(() => {
      set((state) => {
        const entity = state.entities[state.entities.length - 1];
        entity.setVisible(true);
        return { entities: [...state.entities] };
      });
    }, 0);

    setTimeout(() => {
      set((state) => {
        state.entities.forEach((entity) => {
          if (entity.id !== props.id) {
            entity.setFocused(false);
          }
        });
        return { hasVisibleEntities: true, entities: [...state.entities] };
      });
    }, 500);
  },

  close: (id) => {
    set((state) => {
      const index = get().getIndexById(id);
      if (index === -1) return state;
      const entity = state.entities[index];
      entity.setVisible(false);

      if (state.entities.length > 1) {
        state.entities[state.entities.length - 2].setFocused(true);
      }

      const hasVisibleEntities = state.entities.some((item) => item.visible);

      setTimeout(() => {
        set((state) => {
          const index = get().getIndexById(id);
          if (index === -1) return state;
          const entity = state.entities[index];
          entity.setFocused(false);
          state.entities.splice(index, 1);
          return { entities: [...state.entities] };
        });
      }, 500);

      return { hasVisibleEntities, entities: [...state.entities] };
    });
  },

  closeAll: () => {
    get().entities.forEach((entity) => {
      get().close(entity.id);
    });
  },

  bringToFront: (entity) => {
    const index = get().getIndexById(entity.id);
    if (index === -1) return;

    entity.setFocused(true);
    entity.setVisible(true);

    const maxZIndex = get().maxZIndex();
    if (entity.zIndex <= maxZIndex) {
      entity.setZIndex(maxZIndex + 1);
    }

    set((state) => {
      state.entities.forEach((item) => {
        if (item.id !== entity.id) {
          item.setFocused(false);
        }
      });
      return { entities: [...state.entities] };
    });
  },
}));
