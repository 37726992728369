import { Drawer, drawerClasses } from '@mui/material';

import { useWindowSize } from '@keenious/libraries/hooks/useWindowSize';
import { Color } from '@keenious/libraries/lib/colors';

import { sizes } from '../../../style/sizes';

export interface PermanentSidebarProps {
  // "variant"
  variant?: 'window' | 'normal';
  children?: React.ReactNode;
}

export const PermanentSidebar: React.FC<PermanentSidebarProps> = ({
  variant = 'normal',
  children = undefined,
}: PermanentSidebarProps) => {
  const windowSize = useWindowSize();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: variant == 'window' && windowSize.width > sizes.sidebarWidth ? sizes.sidebarWidth : '100%',
        flexShrink: 0,
        [`.${drawerClasses.paper}`]: {
          zIndex: 1,
          width: variant == 'window' && windowSize.width > sizes.sidebarWidth ? sizes.sidebarWidth : '100%',
          borderLeft: 'none',
          background: Color.Grey,
          boxShadow: 'none',
          borderRadius: 0,
        },
      }}
      anchor="right"
    >
      {children}
    </Drawer>
  );
};
