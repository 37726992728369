import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';
import { UserEntity } from '../../../../lib/types';

/**
 * Send a request to check the auth request for the user
 * @param sessionId - The session id
 * @returns The user entity
 */
async function checkAuthRequest(sessionId: string) {
  const { data } = await axios.get<UserEntity>(`/api/auth/requests/${sessionId}`);
  return data;
}

/**
 * Check auth request
 * @returns Check auth request mutation
 */
export function useCheckAuthRequest() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: checkAuthRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['auth'] });
      queryClient.invalidateQueries({ queryKey: ['bookmarks'] });
    },
  });
}
