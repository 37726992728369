import { CitationStyleEnum } from '../lib/types';

export class CitationUtils {
  static CITATION_STYLES = [
    { label: 'MLA', value: CitationStyleEnum.MLA },
    { label: 'Chicago', value: CitationStyleEnum.Chicago },
    { label: 'Harvard', value: CitationStyleEnum.Harvard },
    { label: 'Vancouver', value: CitationStyleEnum.Vancouver },
    { label: 'APA 7th', value: CitationStyleEnum.APA7th },
    { label: 'APA 6th', value: CitationStyleEnum.APA6th },
    { label: 'RIS', value: CitationStyleEnum.RIS },
    { label: 'BibTeX', value: CitationStyleEnum.BibTeX },
    { label: 'CSL', value: CitationStyleEnum.CSL },
  ];

  static citationCountToNumberOrUndefined(citationCount: number | string) {
    let result = citationCount !== '' ? Number(citationCount) : undefined;
    result = Number.isNaN(result) ? undefined : result;
    return result;
  }

  static citationCountToString(citationCount?: number) {
    return String(citationCount ?? '');
  }
}
