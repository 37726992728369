import { TopicsTabPanel } from '@keenious/libraries/components/topics/TopicsTabPanel';
import { useEffectAsync } from '@keenious/libraries/hooks/useEffectAsync';
import { useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useTopicsStore } from '@keenious/libraries/store/useTopicsStore';
import { useUiStore } from '@keenious/libraries/store/useUiStore';

import { TopicCardContainer } from './TopicCardContainer';

export const TopicsTabPanelContainer: React.FC = () => {
  const loading = useUiStore((state) => state.loading);
  const setLoading = useUiStore((state) => state.setLoading);
  const topicsResponse = useTopicsStore((state) => state.topicsResponse);
  const getTopicsByIds = useTopicsStore((state) => state.getTopicsByIds);
  const setVisibleTopics = useTopicsStore((state) => state.setVisibleTopics);
  const visibleTopics = useTopicsStore((state) => state.visibleTopics);
  const filters = useFiltersStore((state) => state.filters);

  useEffectAsync(async () => {
    if (!topicsResponse) return;

    // Filter out topics that are in the include/exclude lists
    const visibleTopicIds = topicsResponse.hits
      .map((hit) => hit.entity.id)
      .filter(
        (id) =>
          !filters.topics_include_list.includes(id) &&
          !filters.topics_exclude_list.includes(id) &&
          !filters.fields_include_list.includes(id) &&
          !filters.fields_exclude_list.includes(id) &&
          !filters.subfields_include_list.includes(id) &&
          !filters.subfields_exclude_list.includes(id),
      );

    const visibleTopics = getTopicsByIds(visibleTopicIds);
    setVisibleTopics(visibleTopics);

    setLoading(false);
  }, [topicsResponse]);

  return (
    <TopicsTabPanel
      topics={visibleTopics}
      loading={loading}
      // AppliedFiltersComponent={<AppliedFiltersContainer query={storage} />}
    >
      {visibleTopics?.map((topic) => <TopicCardContainer key={topic.id} topic={topic} />)}
    </TopicsTabPanel>
  );
};
