export class MathUtils {
  /**
   * Round a number to 2 decimal points
   * @param value - The number to round
   * @param fallback - The fallback value
   * @returns number
   */
  static toDecimal(value: number, fallback = 0) {
    const number = Math.round(value * 100) / 100;
    if (number === Infinity) return fallback;
    return number;
  }

  /**
   * Get a random number between two numbers
   * @param min - The minimum number
   * @param max - The maximum number
   * @returns number
   */
  static getRandomBetween(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
