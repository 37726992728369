import { Lightbulb } from '@mui/icons-material';
import { Box, CardActionArea, Grid, Link, Skeleton, Typography, darken } from '@mui/material';
import { useEffect, useState } from 'react';

import { useEffectAsync } from '../../../hooks/useEffectAsync';
import { AnalyticsUtils } from '../../../lib/analytics';
import { Color } from '../../../lib/colors';
import { TopicWithTypeEntity } from '../../../lib/excover';
import { HighlightedWord, highlightKeywords } from '../../../lib/highlight-keywords';
import { useEntitiesStore } from '../../../store/useEntitiesStore';
import { useFiltersStore } from '../../../store/useFiltersStore';
import { useTopicsStore } from '../../../store/useTopicsStore';
import { useUiStore } from '../../../store/useUiStore';
import { EntityEnum, EntityTab } from '../../../types/entities';
import { LocalStorageUtils } from '../../../utils/LocalStorageUtils';
import { MathUtils } from '../../../utils/MathUtils';
import { Card } from '../../card/Card/Card';
import { CardContent } from '../../card/CardContent/CardContent';
import { CardHeader } from '../../card/CardHeader/CardHeader';
import { TopicFilterButton } from './TopicFilterButton';

const formatter = Intl.NumberFormat('en-US', { notation: 'compact' });

export enum TopicStatus {
  Included = 'included',
  Excluded = 'excluded',
}

export interface ITopicCardProps {
  topic?: TopicWithTypeEntity;
  loading?: boolean;
  onSearch?: () => void;
}

export const TopicCard: React.FC<ITopicCardProps> = ({ topic, loading, onSearch }) => {
  const setEntityTab = useUiStore((state) => state.setEntityTab);
  const open = useEntitiesStore((state) => state.open);
  const queryString = useTopicsStore((state) => state.queryString);
  const isIncludedTopic = useFiltersStore((state) => state.isIncludedTopic);
  const isExcludedTopic = useFiltersStore((state) => state.isExcludedTopic);
  const includeTopic = useFiltersStore((state) => state.includeTopic);
  const unincludeTopic = useFiltersStore((state) => state.unincludeTopic);
  const excludeTopic = useFiltersStore((state) => state.excludeTopic);
  const unexcludeTopic = useFiltersStore((state) => state.unexcludeTopic);

  const [titleParts, setTitleParts] = useState<HighlightedWord[]>([]);
  const [visited, setVisited] = useState(false);

  useEffectAsync(async () => {
    if (!topic?.id) return;
    refreshVisited();
  }, [topic?.id]);

  function refreshVisited() {
    setTimeout(() => {
      const visitedTopics = LocalStorageUtils.get<string[]>('visitedTopics') || [];
      topic?.id && setVisited(visitedTopics.includes(topic.id));
    }, 0);
  }

  function renderDescription() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography
          variant="body2"
          display="block"
          sx={{
            fontSize: 13,
            overflow: 'hidden',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            position: 'relative',
            '& p': { m: 0 },
            ':first-letter': { textTransform: 'capitalize' },
          }}
          className="ph-no-capture"
          dangerouslySetInnerHTML={{ __html: topic?.description }}
        />
      </Box>
    );
  }

  useEffect(() => {
    if (!topic || !queryString) return;
    setTitleParts(highlightKeywords(topic.display_name, queryString));
  }, []);

  const card = (
    <Card
      data-testid={'SearchResultCard'}
      sx={{
        borderTop: `2px solid transparent`,
        borderBottom: `2px solid transparent`,
        marginBottom: 3,
        transition: 'border-top .25s, border-bottom .25s',
        borderLeft: 0,
        borderRadius: 0,
        '& .MuiLink-root': { '&:visited': { color: Color.VisitedLink } },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Lightbulb sx={{ color: `${Color.Topic} !important` }} fontSize="small" />

          <Typography variant="body2" color="secondary" sx={{ fontSize: 13, fontWeight: 'light', ml: 2 }}>
            Topic
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', padding: '4px 2px 2px 2px' }} onClick={(event) => event.stopPropagation()}>
          <TopicFilterButton
            loading={loading}
            status={
              isIncludedTopic(topic?.id)
                ? TopicStatus.Included
                : isExcludedTopic(topic?.id)
                  ? TopicStatus.Excluded
                  : null
            }
            onInclude={() => {
              if (!isIncludedTopic(topic.id)) {
                includeTopic(topic.id);
                AnalyticsUtils.captureEvent('Filters: Added Topic to "Include List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              } else {
                unincludeTopic(topic.id);
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Include List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              }
              setEntityTab(EntityTab.Articles);
              onSearch();
            }}
            onExclude={() => {
              if (!isExcludedTopic(topic.id)) {
                excludeTopic(topic.id);
                AnalyticsUtils.captureEvent('Filters: Added Topic to "Exclude List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              } else {
                unexcludeTopic(topic.id);
                AnalyticsUtils.captureEvent('Filters: Removed Topic from "Exclude List"', {
                  name: topic.display_name,
                  type: topic.type,
                });
              }
              setEntityTab(EntityTab.Articles);
              onSearch();
            }}
          />
        </Box>
      </Box>

      <CardHeader
        sx={{ p: 0 }}
        disableTypography
        title={
          !loading ? (
            <Link
              style={{ color: visited ? Color.VisitedLink : darken(Color.Link, 0.2), textDecoration: 'none' }}
              onClick={refreshVisited}
              title="Open Topic"
            >
              <Typography style={{ lineHeight: 1.2 }} className="ph-no-capture">
                {titleParts.length
                  ? titleParts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.bold ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))
                  : topic?.display_name}
              </Typography>
            </Link>
          ) : (
            <Skeleton width="100%" height={95} sx={{ transform: 'scale(1)' }} />
          )
        }
        subheader={renderDescription()}
      />

      <CardContent sx={{ p: 0, pb: '0 !important' }}>
        <div>
          <Grid container spacing={2} sx={{ my: 1 }}>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption" color="secondary" sx={{ fontSize: 13, fontWeight: 'light' }}>
                {!loading ? (
                  <>{topic?.works_count !== undefined && `${formatter.format(topic?.works_count)} Publications`}</>
                ) : (
                  <Skeleton width={MathUtils.getRandomBetween(50, 100)} />
                )}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <CardActionArea
      component="div"
      disableRipple
      onClick={() => {
        if (!topic) return;
        open({
          id: String(topic.id),
          type: EntityEnum.Topic,
          title: 'Topic',
        });
      }}
      sx={{ userSelect: 'auto' }}
    >
      {card}
    </CardActionArea>
  );
};
