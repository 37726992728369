import { Login, Logout, PictureAsPdf, Settings } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { GraduationCap } from '@keenious/libraries/components/icons/GraduationCap';
import { CustomButton } from '@keenious/libraries/components/new/CustomButton';
import { useAuth } from '@keenious/libraries/hooks/features/useAuth';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { config } from '@keenious/libraries/lib/config';
import { AddonNavigation, ExternalNavigation, WebsiteNavigation } from '@keenious/libraries/lib/navigation';

import { useAddonSignIn } from '../../../hooks/useAddonSignIn';
import { useSearchStore } from '../../../store/useSearchStore';

export const Home: React.FC = () => {
  const { user, institution } = useAuth();

  const { onSignIn, onSignOut, signInUrl } = useAddonSignIn();
  const navigate = useNavigate();

  const applySearch = useSearchStore((state) => state.applySearch);

  return (
    <Box sx={{ p: 6, display: 'flex', flexDirection: 'column', flex: '1 0 auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto' }}>
        <Box sx={{ pt: 8 }}>
          {institution?.welcomeMessage ? (
            <Box
              sx={{
                'h1, h2, h3, h4, h5': { margin: 0 },
                h1: (theme) => theme.typography.h1,
                h2: (theme) => theme.typography.h2,
                h3: (theme) => theme.typography.h3,
                h4: (theme) => theme.typography.h4,
                h5: (theme) => theme.typography.h5,
              }}
            >
              <Box dangerouslySetInnerHTML={{ __html: institution.welcomeMessage }} />
            </Box>
          ) : (
            <Box>
              <Typography variant="h1" align="center">
                Welcome to Keenious
              </Typography>
              <Typography paragraph sx={{ pt: 12 }}>
                Keenious will analyze the text in your document and recommend interesting research for you to explore!
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <CustomButton
            onClick={() => {
              navigate(AddonNavigation.Result);
              AnalyticsUtils.captureEvent('Addon: Clicked "Explore Using Text" Button');
              applySearch();
            }}
            startIcon={<GraduationCap />}
            aria-label="analyze your text to get relevant results"
          >
            Explore using Text
          </CustomButton>

          <Box sx={{ mt: -10 }}>
            <CustomButton
              href={`${config.WEBSITE_URL}${WebsiteNavigation.Explore}`}
              target="_blank"
              color="primary"
              variant="outlined"
              onClick={() => AnalyticsUtils.captureEvent('Addon: Clicked "Explore Using PDF" Button')}
              startIcon={<PictureAsPdf />}
              aria-label="search using a PDF"
            >
              Explore using PDF
            </CustomButton>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 15, textAlign: 'center', mb: 4 }}>
        <Link
          href={ExternalNavigation.HowKeeniousRecommendsArticles}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="link to documentation"
        >
          How does Keenious work?
        </Link>
      </Box>

      <Box sx={{ mt: 6, textAlign: 'center' }}>
        {user ? (
          <Box>
            <Typography variant="caption" display="block">
              Signed in as <strong>{user.email}</strong>
            </Typography>

            <Box>
              <CustomButton
                size="small"
                variant="contained"
                startIcon={<Settings />}
                href={`${config.WEBSITE_URL}${WebsiteNavigation.Settings}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Settings
              </CustomButton>

              <CustomButton size="small" variant="outlined" onClick={onSignOut} startIcon={<Logout />}>
                Sign Out
              </CustomButton>
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography variant="caption" display="block">
              Have an account?
            </Typography>

            <CustomButton
              size="small"
              href={signInUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onSignIn}
              startIcon={<Login />}
            >
              Sign In
            </CustomButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
