import { Dialog, DialogProps, dialogClasses, styled } from '@mui/material';

export const CustomDialog: React.FC<DialogProps> = styled((props: DialogProps) => <Dialog fullWidth {...props} />)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialogTitle-root': { padding: '10px 16px' },
      '& .MuiDialogContent-root': { padding: '8px 16px' },
    },

    [`.${dialogClasses.paper}`]: {
      [theme.breakpoints.down('sm')]: {
        width: `calc(100% - 16px)`,
        margin: 0,
      },
    },
  }),
);
