interface IProps {
  value: number;
  index: number;
  children: React.ReactNode;
}

export const TabPanel: React.FC<IProps> = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {children}
    </div>
  );
};
