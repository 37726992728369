import {
  IdentityProvider,
  IndividualSubscriptionEntity,
  InstitutionEntity,
  TeamEntity,
  TwoFactorAuthenticationEntity,
  UserEntity,
  UserRole,
  UserSettingsEntity,
} from '../types';

export class User implements UserEntity {
  id: string;
  name: string;
  email: string;
  isEmailVerified: boolean;
  emailChange?: string | null;
  role: UserRole;
  // Providers
  providers: IdentityProvider[];
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  // Settings
  settings?: UserSettingsEntity | null;
  // Subscription
  subscription?: IndividualSubscriptionEntity | null;
  // Team
  team?: TeamEntity | null;
  teamId: string | null;
  // Institution
  institution?: InstitutionEntity | null;
  institutionId: string | null;
  // Temporary Institution
  temporaryInstitution?: InstitutionEntity | null;
  temporaryInstitutionId: string | null;
  // Two Factor Authentication
  twoFactorAuthentication?: TwoFactorAuthenticationEntity | null;

  constructor(user?: UserEntity) {
    // Copy all properties from the user object to this instance
    Object.assign(this, user);
  }

  /**
   * Check if a user is a team user
   * @returns Whether the user is a team user
   */
  isTeamUser() {
    return Boolean(this.team);
  }

  /**
   * Check if a user is a team admin
   * @returns Whether the user is a team admin
   */
  isTeamAdmin() {
    return this.isTeamUser() && this.role === UserRole.TEAM_ADMIN;
  }

  /**
   * Checks if the user is an institution admin
   * @returns true if the user is an institution admin and false otherwise
   */
  isInstitutionAdmin() {
    if (!this.institution && !this.temporaryInstitution) {
      return false;
    }

    return this.hasMinimumRole(UserRole.INSTITUTION_ADMIN);
  }

  /**
   * Checks if the user is a Keenious admin
   * @returns true if the user is a Keenious admin and false otherwise
   */
  isKeeniousAdmin() {
    return this.role === UserRole.KEENIOUS_ADMIN && !this.temporaryInstitution;
  }

  /**
   * Checks if the user has access to a resource based on their role and the minimum role required to access the resource
   * @param role - The minimum role required to access the resource
   * @returns true if the user has access to the resource and false otherwise
   */
  hasMinimumRole(role: UserRole) {
    switch (role) {
      case UserRole.GUEST:
        return true;
      case UserRole.USER:
        return this.role === UserRole.USER;
      case UserRole.TEAM_ADMIN:
        return this.role === UserRole.TEAM_ADMIN || this.role === UserRole.KEENIOUS_ADMIN;
      case UserRole.INSTITUTION_ADMIN:
        return this.role === UserRole.INSTITUTION_ADMIN || this.role === UserRole.KEENIOUS_ADMIN;
      case UserRole.KEENIOUS_ADMIN:
        return this.role === UserRole.KEENIOUS_ADMIN;
      default:
        return false;
    }
  }
}
