import { create } from 'zustand';

import { FiltersDto, PaginationEntity } from '../lib/excover';
import { DateUtils } from '../utils/DateUtils';
import { useTopicsStore } from './useTopicsStore';

export const initialFilters: FiltersDto = {
  is_oa: false,
  cited_by_count_min: undefined,
  cited_by_count_max: undefined,
  publication_year_min: 1900,
  publication_year_max: DateUtils.CURRENT_YEAR,
  works_include_list: [],
  works_exclude_list: [],
  dois_include_list: [],
  dois_exclude_list: [],
  sources_include_list: [],
  sources_exclude_list: [],
  topics_include_list: [],
  topics_exclude_list: [],
  fields_include_list: [],
  fields_exclude_list: [],
  subfields_include_list: [],
  subfields_exclude_list: [],
  authors_include_list: [],
  authors_exclude_list: [],
};

type FiltersStore = {
  filters: FiltersDto;
  pagination: PaginationEntity;
  highlightedText: string;
  queryString: string;
  setQueryString: (queryString: string) => void;
  updateFilters: (filters: Partial<FiltersDto>) => void;
  setPagination: (pagination: PaginationEntity) => void;
  setHighlightedText: (highlightedText: string) => void;
  resetFilters: () => void;
  filtersChanged: () => boolean;
  isIncludedTopic(topicId: string): boolean;
  isExcludedTopic(topicId: string): boolean;
  includeTopic(topicId: string): void;
  unincludeTopic(topicId: string): void;
  excludeTopic(topicId: string): void;
  unexcludeTopic(topicId: string): void;
};

export const useFiltersStore = create<FiltersStore>((set, get) => ({
  filters: { ...initialFilters },
  pagination: { offset: 0, limit: 10 },
  highlightedText: '',
  queryString: '',

  setQueryString: (queryString) => set({ queryString }),

  updateFilters: (filters) => {
    set((state) => ({
      filters: {
        ...state.filters,
        ...filters,
      },
    }));
  },

  setPagination: (pagination) => set({ pagination }),

  setHighlightedText: (highlightedText) => set({ highlightedText }),

  resetFilters: () => {
    const pagination = get().pagination;
    set({
      filters: { ...initialFilters },
      pagination: { offset: 0, limit: pagination.limit || 10 },
      highlightedText: '',
    });
  },

  filtersChanged: () => {
    const state = get();
    const filters = state.filters;
    return (
      filters.is_oa ||
      filters.cited_by_count_min !== undefined ||
      filters.cited_by_count_max !== undefined ||
      filters.publication_year_min !== 1900 ||
      filters.publication_year_max !== DateUtils.CURRENT_YEAR ||
      filters.works_include_list.length > 0 ||
      filters.works_exclude_list.length > 0 ||
      filters.dois_include_list.length > 0 ||
      filters.dois_exclude_list.length > 0 ||
      filters.sources_include_list.length > 0 ||
      filters.sources_exclude_list.length > 0 ||
      filters.topics_include_list.length > 0 ||
      filters.topics_exclude_list.length > 0 ||
      filters.fields_include_list.length > 0 ||
      filters.fields_exclude_list.length > 0 ||
      filters.subfields_include_list.length > 0 ||
      filters.subfields_exclude_list.length > 0 ||
      filters.authors_include_list.length > 0 ||
      filters.authors_exclude_list.length > 0
    );
  },

  isIncludedTopic: (topicId) => {
    const { filters } = get();
    return (
      filters.topics_include_list.includes(topicId) ||
      filters.fields_include_list.includes(topicId) ||
      filters.subfields_include_list.includes(topicId)
    );
  },

  isExcludedTopic: (topicId) => {
    const { filters } = get();
    return (
      filters.topics_exclude_list.includes(topicId) ||
      filters.fields_exclude_list.includes(topicId) ||
      filters.subfields_exclude_list.includes(topicId)
    );
  },

  includeTopic: (topicId) => {
    const topic = useTopicsStore.getState().getTopicById(topicId);
    const { filters, updateFilters } = get();

    updateFilters({
      ...(topic.type === 'topic' && {
        topics_include_list: Array.from(new Set([topicId, ...filters.topics_include_list])),
        topics_exclude_list: filters.topics_exclude_list.filter((id) => id !== topicId),
      }),
      ...(topic.type === 'field' && {
        fields_include_list: Array.from(new Set([topicId, ...filters.fields_include_list])),
        fields_exclude_list: filters.fields_exclude_list.filter((id) => id !== topicId),
      }),
      ...(topic.type === 'subfield' && {
        subfields_include_list: Array.from(new Set([topicId, ...filters.subfields_include_list])),
        subfields_exclude_list: filters.subfields_exclude_list.filter((id) => id !== topicId),
      }),
    });
  },

  unincludeTopic: (topicId) => {
    const topic = useTopicsStore.getState().getTopicById(topicId);
    const { filters, updateFilters } = get();

    updateFilters({
      ...(topic.type === 'topic' && {
        topics_include_list: filters.topics_include_list.filter((id) => id !== topicId),
      }),
      ...(topic.type === 'field' && {
        fields_include_list: filters.fields_include_list.filter((id) => id !== topicId),
      }),
      ...(topic.type === 'subfield' && {
        subfields_include_list: filters.subfields_include_list.filter((id) => id !== topicId),
      }),
    });
  },

  excludeTopic: (topicId) => {
    const topic = useTopicsStore.getState().getTopicById(topicId);
    const { filters, updateFilters } = get();

    updateFilters({
      ...(topic.type === 'topic' && {
        topics_include_list: filters.topics_include_list.filter((id) => id !== topicId),
        topics_exclude_list: Array.from(new Set([topicId, ...filters.topics_exclude_list])),
      }),
      ...(topic.type === 'field' && {
        fields_include_list: filters.fields_include_list.filter((id) => id !== topicId),
        fields_exclude_list: Array.from(new Set([topicId, ...filters.fields_exclude_list])),
      }),
      ...(topic.type === 'subfield' && {
        subfields_include_list: filters.subfields_include_list.filter((id) => id !== topicId),
        subfields_exclude_list: Array.from(new Set([topicId, ...filters.subfields_exclude_list])),
      }),
    });
  },

  unexcludeTopic: (topicId) => {
    const topic = useTopicsStore.getState().getTopicById(topicId);
    const { filters, updateFilters } = get();

    updateFilters({
      ...(topic.type === 'topic' && {
        topics_exclude_list: filters.topics_exclude_list.filter((id) => id !== topicId),
      }),
      ...(topic.type === 'field' && {
        fields_exclude_list: filters.fields_exclude_list.filter((id) => id !== topicId),
      }),
      ...(topic.type === 'subfield' && {
        subfields_exclude_list: filters.subfields_exclude_list.filter((id) => id !== topicId),
      }),
    });
  },
}));
