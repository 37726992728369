import { useQuery } from '@tanstack/react-query';
import { RawAxiosRequestConfig } from 'axios';

import { axios } from '../../../lib/axios';
import { BookmarkEntity } from '../../../lib/types';

/**
 * Send a request to get the user's bookmarks
 * @param config - Axios request config
 * @returns The bookmarks
 */
export async function getBookmarks(config?: RawAxiosRequestConfig) {
  try {
    const { data } = await axios.get<BookmarkEntity[]>(`/api/bookmarks`, config);
    return data;
  } catch (error) {
    return [];
  }
}

/**
 * Get the bookmarks of the user
 * @returns Bookmarks query
 */
export function useGetBookmarks() {
  return useQuery({
    queryKey: ['bookmarks'],
    queryFn: () => getBookmarks(),
  });
}
