import { Box, Container, Link, Paper, Typography } from '@mui/material';
import type { FallbackRender } from '@sentry/react';

import KeeniousLogo from '@keenious/libraries/assets/keenious-logo.svg';
import { CustomButton } from '@keenious/libraries/components/new/CustomButton';
import { Color } from '@keenious/libraries/lib/colors';
import { config } from '@keenious/libraries/lib/config';

import { TopBar } from '../TopBar/TopBar';

export const SentryErrorFallback: FallbackRender = ({ error, componentStack }) => {
  return (
    <>
      <TopBar sx={{ backgroundColor: Color.White, flexDirection: 'column', pb: 4 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            my: 12,
            position: 'relative',
          }}
        >
          <Link
            color="inherit"
            href={config.WEBSITE_URL}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
          >
            <KeeniousLogo height={22} />
          </Link>
        </Box>
      </TopBar>

      <Container maxWidth="xl" sx={{ py: '5vh' }}>
        <Container maxWidth="md">
          <Typography variant="h2" gutterBottom align="center">
            Something went wrong
          </Typography>

          <Typography align="center" paragraph>
            You have encountered an unexpected error. If this error continues to persist, please contact us at{' '}
            <Link href="mailto:support@keenious.com" title="Send us an email">
              support@keenious.com
            </Link>
          </Typography>

          <Box sx={{ textAlign: 'center' }}>
            <CustomButton onClick={() => window.location.reload()} aria-label="refresh keenious">
              Refresh
            </CustomButton>
          </Box>

          <Paper variant="outlined" component="pre" sx={{ overflow: 'auto', p: 2, maxHeight: 500 }}>
            {error.toString()}
            {componentStack}
          </Paper>
        </Container>
      </Container>
    </>
  );
};
