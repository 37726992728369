export const config = {
  // Global
  NODE_ENV: process.env.NODE_ENV || 'development',
  VERSION: process.env.VERSION || new Date().toISOString().split('T')[0].replace(/-/g, '.'),
  API_URL: process.env.API_URL || 'http://localhost:4000',
  AXIOS_BASE_URL: process.env.AXIOS_BASE_URL || 'http://localhost:4000',
  WEBSITE_URL: process.env.WEBSITE_URL || 'http://localhost:3000',
  ADDON_URL: process.env.ADDON_URL || 'http://localhost:8080',
  ADDON_BASE_PATH: process.env.ADDON_BASE_PATH || '/',
  // PostHog
  POSTHOG_KEY: process.env.POSTHOG_KEY || '',
  // Sentry
  SENTRY_DSN: process.env.SENTRY_DSN || '',
};
