import { createRoot } from 'react-dom/client';
import { install } from 'resize-observer';

import { App } from './components/common/App/App';
import './style/index.css';

// Alias msCrypto to crypto for IE11
// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((window as any).msCrypto) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.crypto = (window as any).msCrypto;
}

install();

let rootElement = null;
if (document.getElementById('root-web') !== null) {
  rootElement = document.getElementById('root-web');
} else if (document.getElementById('root-gdoc') !== null) {
  rootElement = document.getElementById('root-gdoc');
} else if (document.getElementById('root') !== null) {
  rootElement = document.getElementById('root');
} else if (document.getElementById('root-word') !== null) {
  rootElement = document.getElementById('root-word');
}

createRoot(rootElement).render(<App />);
