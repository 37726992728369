import { useMutation } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';

/**
 * Send a request to create the auth request for the user
 * @param sessionId - The session id
 */
async function createAuthRequest(sessionId: string) {
  const { data } = await axios.post<void>(`/api/auth/requests`, { sessionId });
  return data;
}

/**
 * Create auth request
 * @returns Create auth request mutation
 */
export function useCreateAuthRequest() {
  return useMutation({
    mutationFn: createAuthRequest,
  });
}
