/* eslint-disable @typescript-eslint/ban-ts-comment */
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

export class StringUtils {
  /**
   * Capitalize the first letter of a string
   * @param str - The string to capitalize
   * @returns The capitalized string
   */
  static capitalizeFirstLetter(str: string) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Format a string to fit a certain length, adding an ellipsis if necessary
   * @param htmlString - The string to format (may contain HTML tags)
   * @param maxLength - The maximum length of the string
   * @param addEllipsis - Whether to add an ellipsis if the string is too long
   * @returns The formatted string
   */
  static fitToLength(htmlString: string, maxLength: number, addEllipsis = true) {
    if (!maxLength) {
      return htmlString;
    }

    if (!htmlString) {
      return '';
    }

    // Create a temporary DOM element to parse HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    const innerText = tempDiv.innerText || tempDiv.textContent || '';

    // If the inner text is shorter than or equal to maxLength, return the original HTML string
    if (innerText.length <= maxLength) {
      return htmlString;
    }

    let currentLength = 0;
    let finalHtml = '';
    let insideTag = false;

    // Iterate through each character in the HTML string
    for (let i = 0; i < htmlString.length; i++) {
      const char = htmlString[i];
      finalHtml += char; // Add current character to final HTML string

      if (char === '<') {
        insideTag = true; // We are inside an HTML tag
      } else if (char === '>') {
        insideTag = false; // We are outside an HTML tag
      } else if (!insideTag) {
        currentLength++; // Count characters that are not part of HTML tags
      }

      if (currentLength >= maxLength) {
        break; // Stop if we've reached the desired length
      }
    }

    // Add ellipsis if required and we've reached or exceeded the maxLength
    if (addEllipsis && currentLength >= maxLength) {
      finalHtml += '...';
    }

    // Ensure the final HTML is valid by using another temporary div to close any open tags
    const closingDiv = document.createElement('div');
    closingDiv.innerHTML = finalHtml;
    return closingDiv.innerHTML; // Return the well-formed HTML string
  }

  /**
   * Sanitize and decode a string
   * @param str - The string to sanitize
   * @returns sanitized string
   */
  static sanitize(str: string) {
    if (!str) return '';
    return DOMPurify.sanitize(decode(str));
  }

  /**
   * Remove unicode characters from a string
   * @param str - The string to remove unicode characters from
   * @returns The string without unicode characters
   */
  static removeNonGlyphCharacters(str: string) {
    if (!str) return '';
    // Define a regular expression to match all visible Unicode characters
    // This regex includes letters, numbers, punctuation, symbols, and spaces
    // @ts-ignore
    const regex = /[^\p{L}\p{N}\p{P}\p{S}\p{Z}\p{M}]/gu;
    return str.replace(regex, '');
  }

  static isString(value: unknown): value is string {
    return typeof value === 'string';
  }
}
