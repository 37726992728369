import { HighlightedTextTidbit } from '@keenious/libraries/components/filters/HighlightedTextTidbit';

import { useEditorStore } from '../../store/useEditorStore';

export const HighlightedTextTidbitContainer: React.FC = () => {
  const setIgnoredHighlightedText = useEditorStore((state) => state.setIgnoredHighlightedText);
  const setHighlightedText = useEditorStore((state) => state.setHighlightedText);
  const highlightedText = useEditorStore((state) => state.highlightedText);

  return (
    <HighlightedTextTidbit
      onCancel={() => {
        setIgnoredHighlightedText(highlightedText);
        setHighlightedText('');
      }}
    />
  );
};
