export enum Environment {
  Web = 'addon-web',
  GoogleDocs = 'addon-gdoc',
  Word = 'addon-word',
}

export const environment = (() => {
  const isWeb = typeof document === 'undefined' || document.getElementById('root-web') !== null;
  const isGoogleDocs = document.getElementById('root-gdoc') !== null || document.getElementById('root') !== null;
  const isWord = document.getElementById('root-word') !== null;

  if (isWeb) {
    return Environment.Web;
  } else if (isGoogleDocs) {
    return Environment.GoogleDocs;
  } else if (isWord) {
    return Environment.Word;
  }
})();
