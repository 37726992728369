export class LocalStorageUtils {
  static get<T>(key: string): T | undefined {
    if (typeof window === 'undefined' || typeof localStorage === 'undefined') return;

    let item: string;
    try {
      item = localStorage.getItem(key);
    } catch (e) {
      return undefined;
    }

    if (!item) return undefined;

    try {
      return JSON.parse(item);
    } catch (error) {
      return item as unknown as T;
    }
  }

  static set(key: string, value: unknown) {
    if (typeof window === 'undefined' || typeof localStorage === 'undefined') return;

    let stringifiedValue = value as string;

    if (typeof value !== 'string') stringifiedValue = JSON.stringify(value);
    try {
      localStorage.setItem(key, stringifiedValue);
    } catch (e) {
      // Remove previous search keys if failed to set new key
      LocalStorageUtils.clearKeysStartingWith('search-');
    }
  }

  static remove(key: string) {
    if (typeof window === 'undefined' || typeof localStorage === 'undefined') return;

    try {
      localStorage.removeItem(key);
    } catch (e) {
      // Ignore error
    }
  }

  static clearKeysStartingWith(prefix: string) {
    Object.keys(localStorage)
      .filter((key) => key.startsWith(prefix))
      .forEach((key) => LocalStorageUtils.remove(key));
  }
}
