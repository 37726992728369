import { TextField, TextFieldProps, filledInputClasses, styled } from '@mui/material';

import { Color } from '../../lib/colors';

export const textFieldOverrides = {
  [`& .${filledInputClasses.input}`]: {
    borderRadius: '8px !important',
  },

  [`& .${filledInputClasses.root}`]: {
    borderRadius: '8px !important',

    '&::before, &::after': {
      borderBottom: 'none !important',
    },

    // If not disabled, use custom background
    '&:not(.Mui-disabled)': {
      background: Color.Grey,
    },
  },
};

export const FilledTextField: React.FC<TextFieldProps> = styled((props: TextFieldProps) => (
  <TextField variant="filled" {...props} />
))(textFieldOverrides);
