export class SentryUtils {
  static ignoredPhrases = [
    'Possible side-effect in debug-evaluate',
    `The batch function passed to the ".run" method didn't return a promise`,
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'Request aborted',
    'Unexpected EOF',
    'MicrosoftAjax.js is not loaded successfully',
    'Non-Error promise rejection captured',
    'Loading chunk',
    'Network Error',
  ];
}
