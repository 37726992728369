import { TopicStatus } from '@keenious/libraries/components/topics/Topic/TopicCard';
import { TopicHeader } from '@keenious/libraries/components/topics/TopicPage/TopicHeader';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { TopicWithTypeEntity } from '@keenious/libraries/lib/excover';
import { useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useUiStore } from '@keenious/libraries/store/useUiStore';
import { EntityTab } from '@keenious/libraries/types/entities';

import { useSearchStore } from '../../../store/useSearchStore';

interface IProps {
  topic: TopicWithTypeEntity;
}

export const TopicHeaderContainer: React.FC<IProps> = ({ topic }) => {
  const setEntityTab = useUiStore((state) => state.setEntityTab);
  const isIncludedTopic = useFiltersStore((state) => state.isIncludedTopic);
  const isExcludedTopic = useFiltersStore((state) => state.isExcludedTopic);
  const includeTopic = useFiltersStore((state) => state.includeTopic);
  const unincludeTopic = useFiltersStore((state) => state.unincludeTopic);
  const excludeTopic = useFiltersStore((state) => state.excludeTopic);
  const unexcludeTopic = useFiltersStore((state) => state.unexcludeTopic);
  const resetAndApplySearch = useSearchStore((state) => state.resetAndApplySearch);

  return (
    <TopicHeader
      topic={topic}
      status={
        isIncludedTopic(topic?.id) ? TopicStatus.Included : isExcludedTopic(topic?.id) ? TopicStatus.Excluded : null
      }
      onInclude={(topic) => {
        includeTopic(topic.id);
        AnalyticsUtils.captureEvent('Filters: Added Topic to "Include List"', {
          name: topic.display_name,
          type: topic.type,
        });
        setEntityTab(EntityTab.Articles);
        resetAndApplySearch();
      }}
      onExclude={(topic) => {
        excludeTopic(topic.id);
        AnalyticsUtils.captureEvent('Filters: Added Topic to "Exclude List"', {
          name: topic.display_name,
          type: topic.type,
        });
        setEntityTab(EntityTab.Articles);
        resetAndApplySearch();
      }}
      onRemoveInclude={(topic) => {
        unincludeTopic(topic.id);
        AnalyticsUtils.captureEvent('Filters: Removed Topic from "Include List"', {
          name: topic.display_name,
          type: topic.type,
        });
        setEntityTab(EntityTab.Articles);
        resetAndApplySearch();
      }}
      onRemoveExclude={(topic) => {
        unexcludeTopic(topic.id);
        AnalyticsUtils.captureEvent('Filters: Removed Topic from "Exclude List"', {
          name: topic.display_name,
          type: topic.type,
        });
        setEntityTab(EntityTab.Articles);
        resetAndApplySearch();
      }}
    />
  );
};
