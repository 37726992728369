import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const PreviewTooltip: React.FC<TooltipProps> = styled<typeof Tooltip>(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.palette.background.default,
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
    boxShadow: '0 0 30px rgba(0, 0, 0, 0.3)',
    padding: 0,
    borderRadius: 6,
    margin: '7px 0 8px 0',
    width: 300,
  },
  [`& .${tooltipClasses.arrow}`]: { color: theme.palette.background.default },
}));
