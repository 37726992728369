import { useAlertsStore } from '@keenious/libraries/store/useAlertsStore';
import { IEditorText } from '@keenious/libraries/types/editor';

import { useEditorStore } from '../store/useEditorStore';

export class OfficeUtils {
  static initialized = false;

  static initialize() {
    Office.initialize = function () {};
    Office.onReady(function (info) {
      OfficeUtils.initialized = true;

      Office.context.document?.addHandlerAsync(
        Office.EventType.DocumentSelectionChanged,
        OfficeUtils.isHighlightedTextEmpty,
      );
      OfficeUtils.isHighlightedTextEmpty();

      console.info(`Office.js is now ready in ${info.host} on ${info.platform}`);
    });
  }

  static isHighlightedTextEmpty() {
    if (!OfficeUtils.initialized) {
      return;
    }

    Word.run(async (context) => {
      const selection = context.document.getSelection();
      context.load(selection, 'text');
      context.sync().then(() => {
        const highlightedText = selection.text;
        useEditorStore.getState().setHighlightedText(highlightedText);
        if (highlightedText !== useEditorStore.getState().ignoredHighlightedText) {
          useEditorStore.getState().setIgnoredHighlightedText('');
        }
      });
    });
  }

  static async getText() {
    return await new Promise<IEditorText>((resolve, reject) => {
      if (!OfficeUtils.initialized) {
        reject('Office.js is not initialized');
      }

      Word.run(async (context) => {
        if (!context) {
          useAlertsStore.getState().createAlert({ content: 'Word context is not available' });
          throw new Error('No context found');
        }

        const body = context.document.body;
        const selection = context.document.getSelection();
        context.load(body, 'text');
        context.load(selection, 'text');

        return context
          .sync()
          .then(() => {
            const cleanBodyText = body.text;
            const cleanHighlightedText = selection.text;
            resolve({ text: cleanBodyText, highlightedText: cleanHighlightedText });
          })
          .catch((error) => {
            useAlertsStore.getState().createAlert({ content: error.message });
            reject(error);
          });
      });
    });
  }
}
