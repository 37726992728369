import { createTheme, responsiveFontSizes } from '@mui/material';
import { deepmerge } from '@mui/utils';

import { theme as baseTheme } from '@keenious/libraries/lib/theme';

import { sizes } from './sizes';

export let theme = createTheme(
  deepmerge(baseTheme, {
    typography: {
      h1: { fontSize: 42, lineHeight: 0.95 },
      h2: { fontSize: 36, lineHeight: 0.94 },
      h3: { fontSize: 30, lineHeight: 1.1 },
      h4: { fontSize: 20, lineHeight: 1.16 },
      h5: { fontSize: 14, lineHeight: 1.28 },
    },
    spacing: sizes.spacing,
    components: {
      MuiFormHelperText: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiInputBase: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiInputLabel: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFab: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTable: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiToolbar: {
        defaultProps: {
          variant: 'dense',
        },
      },
      MuiChip: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiCardMedia: {
        styleOverrides: {
          root: {
            border: 'none',
          },
        },
      },
    },
  }),
);

theme = responsiveFontSizes(theme);
