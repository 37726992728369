import { InputBase, InputLabel, Paper } from '@mui/material';
import { useFormik } from 'formik';

import { useEditorStore } from '../../../store/useEditorStore';

export const Editor: React.FC = () => {
  const padding = 6;
  const paddingVertical = 2 * padding;
  const paddingHorizontal = padding;

  const text = useEditorStore((state) => state.text);
  const setText = useEditorStore((state) => state.setText);
  const setHighlightedText = useEditorStore((state) => state.setHighlightedText);
  const ignoredHighlightedText = useEditorStore((state) => state.ignoredHighlightedText);
  const setIgnoredHighlightedText = useEditorStore((state) => state.setIgnoredHighlightedText);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      text: text,
    },
    onSubmit: (values) => {
      setText(values.text);
    },
  });

  /**
   * Set the selected text
   * @param event - select event
   */
  function handleSelect(event: React.SyntheticEvent<HTMLDivElement, Event>) {
    const editor = event.target as HTMLTextAreaElement;
    const highlightedText = editor.value.substring(editor.selectionStart, editor.selectionEnd);
    setHighlightedText(highlightedText || '');
    if (highlightedText !== ignoredHighlightedText) {
      setIgnoredHighlightedText('');
    }
  }

  return (
    <Paper
      sx={{
        borderRadius: '0 !important',
        margin: '0 auto',
        padding: {
          xs: `${paddingHorizontal}vh ${paddingHorizontal}vw`,
          md: `${paddingVertical}vh ${paddingHorizontal}vw`,
        },
        minHeight: '100%',
        maxWidth: 900,
        boxShadow: 'none',
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <InputLabel htmlFor="editor-textarea"></InputLabel>
      <InputBase
        className="ph-no-capture"
        id="editor-textarea"
        sx={{
          width: '100%',
          minHeight: '100%',
          '& textarea': {
            minHeight: {
              sm: `calc(${100 - paddingVertical}vh - 50px)`,
              md: `calc(${100 - paddingVertical * 2}vh - 50px)`,
            },
          },
        }}
        placeholder=""
        multiline
        name="text"
        value={formik.values.text}
        autoFocus={true}
        inputProps={{ style: { border: 'none', lineHeight: 1.5 } }}
        onChange={(event) => {
          formik.handleChange(event);
          formik.handleSubmit();
        }}
        onBlur={formik.handleBlur}
        onSelect={handleSelect}
      />
    </Paper>
  );
};
