import { Box } from '@mui/material';
import { useState } from 'react';

import {
  IPublicationPageProps,
  PublicationPage,
} from '@keenious/libraries/components/publication/PublicationPage/PublicationPage';
import { TopicWithTypeEntity, WorkEntity } from '@keenious/libraries/lib/excover';
import { useEntitiesStore } from '@keenious/libraries/store/useEntitiesStore';
import { useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useTopicsStore } from '@keenious/libraries/store/useTopicsStore';
import { EntityEnum } from '@keenious/libraries/types/entities';

import { PublicationBookmarkButton } from '../PublicationBookmarkButton';
import { PublicationTopicsContainer } from './PublicationTopicsContainer';
import { SimilarArticlesContainer } from './SimilarArticlesContainer';

interface IProps extends IPublicationPageProps {
  id: string;
  children?: React.ReactNode;
}

export const PublicationPageContainer: React.FC<IProps> = ({ id, ...props }) => {
  const [work, setWork] = useState<WorkEntity | null>(null);
  const [topics, setTopics] = useState<TopicWithTypeEntity[] | null>(null);

  const open = useEntitiesStore((state) => state.open);
  const queryString = useFiltersStore((state) => state.queryString);
  const cacheAndMergeTopics = useTopicsStore((state) => state.cacheAndMergeTopics);

  return (
    <PublicationPage
      id={id}
      work={work}
      onLoad={async (response) => {
        setWork(response);
        const topics = response.topics;
        const fields = response.fields;
        const subfields = response.subfields;
        const newTopics = await cacheAndMergeTopics({ topics, fields, subfields });
        setTopics(newTopics);
      }}
      keywords={queryString}
      PublicationBookmarkButtonComponent={<PublicationBookmarkButton work={work} />}
      {...props}
    >
      <Box sx={{ mx: -4 }}>
        {/* <Divider /> */}

        <PublicationTopicsContainer
          topics={topics}
          onTopicClick={(topic) => {
            open({
              id: String(topic.id),
              type: EntityEnum.Topic,
              title: 'Topic',
            });
          }}
        />
        {/* <Divider sx={{ mt: 4, mx: -4 }} />
          <PublicationAuthors authors={work?.paper.Authors} /> */}
        {/* <Divider />
          <PublicationCitedBy
            citationCount={work?.citations.length}
            onViewAll={() => {
              AnalyticsUtils.captureEvent(OpenedCitedByPage);
              open({
                id: `${id}-citedBy`,
                type: EntityEnum.Generic,
                title: 'Cited By',
                getTemplate: () => <PublicationCitedByPage title={work?.paper.Title} paperId={id} />,
              });
            }}
          />
          <Divider />

          <PublicationReferences
            referencesCount={work?.references.length}
            onViewAll={() => {
              AnalyticsUtils.captureEvent(OpenedReferencesPage);
              open({
                id: `${id}-references`,
                type: EntityEnum.Generic,
                title: 'References',
                getTemplate: () => <PublicationReferencesPage title={work?.paper.Title} paperId={id} />,
              });
            }}
          />
          <Divider /> */}
      </Box>

      <SimilarArticlesContainer work={work} />
    </PublicationPage>
  );
};
