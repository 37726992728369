import { Close } from '@mui/icons-material';
import { Box, DialogContent, DialogTitle, Divider, Grid, IconButton, Slider, Typography } from '@mui/material';

import { Color } from '../../../lib/colors';
import { WorkEntity, WorkMetaEntity } from '../../../lib/excover';
import { ExternalNavigation } from '../../../lib/navigation';
import { MathUtils } from '../../../utils/MathUtils';
import { ExternalLink } from '../../common/ExternalLink';
import { CustomDialog } from '../../new/CustomDialog';
import { PublicationChip } from '../PublicationChip/PublicationChip';

interface IProps {
  work: WorkEntity;
  meta: WorkMetaEntity;
  open: boolean;
  onClose: () => void;
}

export const ResultInsightsDialog: React.FC<IProps> = ({ work, meta, open, onClose }) => {
  const recencyBoost = MathUtils.toDecimal((meta.recency_boost - 1) * 100);
  const citedByCountBoost = MathUtils.toDecimal((meta.cited_by_count_boost - 1) * 100);

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container spacing={6}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h4" sx={{ fontSize: '1.4rem !important' }}>
              Result Insights
            </Typography>

            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mt: 0, mb: 4 }}>
          <PublicationChip key={work.id} label={work.title} sx={{ m: 1 }} size="medium" />
        </Box>

        <Typography variant="caption" sx={{ display: 'block' }}>
          Breaks down the factors of why Keenious thinks this article is relevant to your text.
          <br />
          <ExternalLink href={ExternalNavigation.ResultInsights} underline="always" color="inherit">
            Learn more about how this works.
          </ExternalLink>
        </Typography>

        <Divider sx={{ my: 10 }} />

        <Box sx={{ mb: 10 }}>
          <Typography variant="h4" sx={{ fontSize: '1.2rem !important' }}>
            Text Relevance
          </Typography>

          <Typography>
            How the balance between keywords and AI-predicted meaning influences this article&apos;s relevance.
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'stretch', gap: 4, my: 4 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                fontSize: '1.1rem',
                background: '#05C9D6',
                color: Color.White,
                flex: 1,
                px: 6,
                py: 4,
                borderRadius: 90,
              }}
            >
              {Math.floor(meta.keywords_vector_balance * 100)}%
            </Typography>

            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                fontSize: '1.1rem',
                background: '#FFA1CB',
                color: Color.White,
                minWidth: 50,
                width: `${100 - Math.floor(meta.keywords_vector_balance * 100)}%`,
                textAlign: 'right',
                px: 6,
                py: 4,
                borderRadius: 90,
              }}
            >
              {100 - Math.floor(meta.keywords_vector_balance * 100)}%
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'stretch', gap: 16, my: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 4 }}>
              <Box sx={{ width: 14, height: 14, borderRadius: 90, background: '#05C9D6' }} />
              <Typography>Keywords</Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 4 }}>
              <Box sx={{ width: 14, height: 14, borderRadius: 90, background: '#FFA1CB' }} />
              <Typography>AI Predicted Meaning</Typography>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ my: 12 }} />

        <Box>
          <Typography variant="h4" sx={{ fontSize: '1.2rem !important' }}>
            Recency Boost
          </Typography>

          <Typography>Percentage boost in score based on the year of publication or submission.</Typography>

          <Typography sx={{ mt: 4, mb: -4 }}>
            {work.publication_year} → <strong>{parseFloat(recencyBoost.toFixed(1))}%</strong> boost
          </Typography>

          <Slider
            value={meta.recency_boost_normalized * 100}
            marks={[
              { value: 0, label: '0%' },
              { value: 50, label: '2.5%' },
              { value: 100, label: '5%' },
            ]}
            min={0}
            max={100}
            sx={{
              pointerEvents: 'none',
              borderRadius: 4,
              height: 20,
              position: 'relative',
              '.MuiSlider-thumb': {
                display: 'none',
              },
              '.MuiSlider-rail': {
                background: '#e0e0e0',
              },
              '.MuiSlider-mark': {
                display: 'none',
              },
              '.MuiSlider-markLabel': {
                fontSize: '0.9rem',
                color: Color.Black,
                mt: 6,
                '&[data-index="0"]': {
                  transform: 'none',
                },
                '&[data-index="2"]': {
                  transform: 'translateX(-100%)',
                },
              },
              '.MuiSlider-track': {
                background: `linear-gradient(to left, #C58CFF ${recencyBoost + 0}%, #FFA1CB ${recencyBoost + 48}%, #FFC680 ${recencyBoost + 100}%)`,
                borderRadius: 4,
                border: 'none',
              },
            }}
          />
        </Box>

        <Divider />

        <Box sx={{ mt: 12 }}>
          <Typography variant="h4" sx={{ fontSize: '1.2rem !important' }}>
            Citation Count Boost
          </Typography>

          <Typography>Percentage boost in score based on the number of other papers that cites this paper.</Typography>

          <Typography sx={{ mt: 4, mb: -4 }}>
            {work.cited_by_count} citations → <strong>{parseFloat(citedByCountBoost.toFixed(1))}%</strong> boost
          </Typography>

          <Slider
            value={meta.cited_by_count_boost_normalized * 100}
            marks={[
              { value: 0, label: '0%' },
              { value: 50, label: '3%' },
              { value: 100, label: '6%' },
            ]}
            min={0}
            max={100}
            sx={{
              pointerEvents: 'none',
              borderRadius: 4,
              height: 20,
              position: 'relative',
              '.MuiSlider-thumb': {
                display: 'none',
              },
              '.MuiSlider-rail': {
                background: '#e0e0e0',
              },
              '.MuiSlider-mark': {
                display: 'none',
              },
              '.MuiSlider-markLabel': {
                fontSize: '0.9rem',
                color: Color.Black,
                mt: 6,
                '&[data-index="0"]': {
                  transform: 'none',
                },
                '&[data-index="2"]': {
                  transform: 'translateX(-100%)',
                },
              },
              '.MuiSlider-track': {
                background: `linear-gradient(to left, #73E2FF ${citedByCountBoost + 0}%, #92FFC6 ${citedByCountBoost + 48}%, #E2FFC2 ${citedByCountBoost + 100}%)`,
                borderRadius: 4,
                border: 'none',
              },
            }}
          />
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
