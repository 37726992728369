// Authors
export type AuthorEntity = {
  id: string;
  display_name: string;
  orcid?: string | null;
};

export enum AuthorPositionEnum {
  FIRST = 'first',
  MIDDLE = 'middle',
  LAST = 'last',
}

export type AuthorshipEntity = {
  author: AuthorEntity;
  author_position: AuthorPositionEnum;
  institutions: OpenAlexInstitutionEntity[];
  is_corresponding: boolean;
  raw_affiliation_string: string;
  raw_affiliation_strings: string[];
};

// Sources
export enum SourceTypeEnum {
  JOURNAL = 'journal',
  REPOSITORY = 'repository',
  CONFERENCE = 'conference',
  EBOOK_PLATFORM = 'ebook platform',
}

export type SourceEntity = {
  id: string;
  issn_l: string;
  issn: string[];
  is_oa: boolean;
  is_in_doaj: boolean;
  display_name: string;
  host_organization: string;
  host_organization_name: string;
  host_organization_lineage: string[];
  host_organization_lineage_names: string[];
  type: SourceTypeEnum;
};

// Institutions
export enum InstitutionTypeEnum {
  EDUCATION = 'education',
  HEALTHCARE = 'healthcare',
  COMPANY = 'company',
  ARCHIVE = 'archive',
  NONPROFIT = 'nonprofit',
  GOVERNMENT = 'government',
  FACILITY = 'facility',
  OTHER = 'other',
}

export type OpenAlexInstitutionEntity = {
  id: string;
  display_name: string;
  ror: string;
  country_code: string;
  type: InstitutionTypeEnum;
};

// Topics
export type TopicEntity = {
  id: string;
  display_name: string;
  description: string;
  works_count: number;
  cited_by_count: number;
  ids?: {
    openalex?: string;
    wikidata?: string;
    wikipedia?: string;
    mag?: number;
  };
};

// Works
export enum WorkTypeEnum {
  BOOK_SECTION = 'book-section',
  MONOGRAPH = 'monograph',
  REPORT_COMPONENT = 'report-component',
  REPORT = 'report',
  PEER_REVIEW = 'peer-review',
  BOOK_TRACK = 'book-track',
  JOURNAL_ARTICLE = 'journal-article',
  PART = 'book-part',
  OTHER = 'other',
  BOOK = 'book',
  JOURNAL_VOLUME = 'journal-volume',
  BOOK_SET = 'book-set',
  REFERENCE_ENTRY = 'reference-entry',
  JOURNAL = 'journal',
  COMPONENT = 'component',
  BOOK_CHAPTER = 'book-chapter',
  PROCEEDINGS_SERIES = 'proceedings-series',
  REPORT_SERIES = 'report-series',
  PROCEEDINGS = 'proceedings',
  DATABASE = 'database',
  STANDARD = 'standard',
  REFERENCE_BOOK = 'reference-book',
  POSTED_CONTENT = 'posted-content',
  JOURNAL_ISSUE = 'journal-issue',
  DISSERTATION = 'dissertation',
  GRANT = 'grant',
  DATASET = 'dataset',
  BOOK_SERIES = 'book-series',
  EDITED_BOOK = 'edited-book',
}

export type WorkEntity = {
  id: string;
  doi: string;
  title: string;
  publication_year: number;
  publication_date: string;
  cited_by_count: number;
  referenced_works_count: number;
  authorships: AuthorshipEntity[];
  authorships_count?: number;
  first_author_name?: string;
  type: WorkTypeEnum;
  open_access: WorkOpenAccessEntity;
  topics: TopicEntity[];
  fields: TopicEntity[];
  subfields: TopicEntity[];
  primary_location: LocationEntity;
  primary_location_name?: string;
  biblio: BiblioEntity;
  best_oa_location: LocationEntity;
  language: string;
  abstract?: string;
};

// Bibliography
export type BiblioEntity = {
  volume?: string | null;
  issue?: string | null;
  first_page?: string | null;
  last_page?: string | null;
};

// Open Access
export enum OpenAccessStatusEnum {
  GOLD = 'gold',
  GREEN = 'green',
  HYBRID = 'hybrid',
  BRONZE = 'bronze',
  CLOSED = 'closed',
}

export type WorkOpenAccessEntity = {
  any_repository_has_fulltext: boolean;
  is_oa: boolean;
  oa_status: OpenAccessStatusEnum;
  oa_url: string;
};

// Location
export type LocationEntity = {
  is_oa: boolean;
  landing_page_url: string;
  pdf_url?: string | null;
  source: SourceEntity;
  license: string;
  version: string;
};

// Topics Search
export type ExcoverTopicsSearchDto = PaginationEntity & {
  query_string: string;
  synthesize: boolean;
};

// Query
export type ExcoverQueryDto = PaginationEntity & {
  query_texts: ExcoverQueryTextDto[];
  filters?: FiltersDto;
  params?: ParamsDto;
  query_string?: string;
};

// Query Text
export type ExcoverQueryTextDto = {
  tag: ExcoverQueryTextTagEnum;
  text: string;
  weight?: number;
  translate?: boolean;
};

export enum ExcoverQueryTextTagEnum {
  DOCUMENT = 'document',
  HIGHLIGHT = 'highlight',
}

// Similar Works
export type ExcoverSimilarDto = PaginationEntity & {
  work_id?: string;
  filters?: FiltersDto;
  params?: ParamsDto;
};

// Filters
export type FiltersDto = {
  is_oa?: boolean;
  has_abstract?: boolean;
  cited_by_count_min?: number;
  cited_by_count_max?: number;
  publication_year_min?: number;
  publication_year_max?: number;
  works_include_list?: string[];
  works_exclude_list?: string[];
  dois_include_list?: string[];
  dois_exclude_list?: string[];
  sources_include_list?: string[];
  sources_exclude_list?: string[];
  topics_include_list?: string[];
  topics_exclude_list?: string[];
  fields_include_list?: string[];
  fields_exclude_list?: string[];
  subfields_include_list?: string[];
  subfields_exclude_list?: string[];
  authors_include_list?: string[];
  authors_exclude_list?: string[];
};

// Params
export type ParamsDto = {
  reverse_ranking?: boolean;
  max_length_trimmed_text_keywords?: number;
  max_length_trimmed_text_vector?: number;
  yake_n?: number;
  yake_deduplim?: number;
  yake_top_min?: number;
  yake_top_max?: number;
  max_terms?: number;
  multigram_multiplier?: number;
  opensearch_min_score?: number;
  opensearch_size?: number;
  opensearch_title_weight?: number;
  query_string_boost?: number;
  qdrant_score_threshold?: number;
  qdrant_limit?: number;
  qdrant_hnsw_ef?: number;
  vector_weight?: number;
  vector_exponent?: number;
  keywords_exponent?: number;
  keywords_score_threshold?: number;
  min_relevance_score?: number;
  vector_score_threshold?: number;
  recency_boost_factor?: number;
  cited_by_count_boost_factor?: number;
};

// Entities
export type WorkMetaEntity = {
  rank: number;
  score: number;
  cited_by_count_boost: number;
  cited_by_count_boost_normalized: number;
  cited_by_count_score: number;
  keywords_score: number;
  keywords_vector_balance: number;
  recency_boost: number;
  recency_boost_normalized: number;
  recency_score: number;
  relevance_score: number;
  vector_score: number;
};

export type ExcoverQueryEntity = {
  hits: {
    meta: WorkMetaEntity;
    entity: WorkEntity;
  }[];

  meta: {
    time: number;
    offset: number;
    count: number;
    total_hits: number;
    query_texts: {
      tag: ExcoverQueryTextTagEnum;
      weight: number;
      detected_language_name: string;
      translated: boolean;
      translation_from_cache: boolean;
    }[];
  };
};

export type TopicType = 'field' | 'subfield' | 'topic';

export type TopicWithTypeEntity = TopicEntity & { type: TopicType };

export type ExcoverTopicsSearchEntity = {
  meta: {
    time: number;
    offset: number;
    count: number;
  };

  hits: {
    meta: {
      rank: number;
      score: number;
    };

    entity: TopicWithTypeEntity;
  }[];
};

export type PaginationEntity = {
  offset?: number;
  limit?: number;
};
