import { ExpandMore, InfoOutlined, StarBorder } from '@mui/icons-material';
import { Box, BoxProps, IconButton, Tooltip, Typography } from '@mui/material';

import { useEffectAsync } from '../../../hooks/useEffectAsync';
import { useScrolledTo } from '../../../hooks/useScrolledTo';
import { Color } from '../../../lib/colors';
import { ExcoverQueryEntity } from '../../../lib/excover';
import { Loader } from '../../common/Loader/Loader';
import { CustomButton } from '../../new/CustomButton';
import { PublicationCard } from '../PublicationCard/PublicationCard';

export interface ISimilarArticlesProps {
  workId?: string;
  hits?: ExcoverQueryEntity['hits'];
  loading?: boolean;
  hasMore?: boolean;
  headerProps?: BoxProps;
  onScrollDown?: () => void;
  onSeeMoreClick?: () => void;
  onMount?: () => void;
  children?: React.ReactNode;
}

export const SimilarArticles: React.FC<ISimilarArticlesProps> = ({
  workId,
  hits,
  loading,
  hasMore,
  headerProps,
  onScrollDown,
  onSeeMoreClick,
  onMount,
  children,
}) => {
  const ref = useScrolledTo({ onScroll: onScrollDown! });

  useEffectAsync(async () => {
    if (!workId) return;
    onMount && (await onMount());
  }, [workId]);

  return (
    <Box sx={{ mx: -4 }}>
      <Box
        sx={{
          ...headerProps?.sx,
          mt: 6,
          background: Color.Purple,
          color: Color.White,
          p: 6,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontSize: 17, fontWeight: 500, mr: 4 }}>Similar Articles</Typography>

        <Tooltip title="These suggestions are powered by our in-house AI recommendation engine">
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </Box>

      <Box sx={{ background: Color.Grey }} ref={ref}>
        {hits
          ? children
          : [...Array(10)].map((item, index) => (
              <PublicationCard
                key={index}
                disableResultInsights
                PublicationBookmarkButtonComponent={
                  <IconButton disabled size="small" style={{ padding: 2 }}>
                    <StarBorder sx={{ fontSize: 22 }} />
                  </IconButton>
                }
              />
            ))}
        <Box sx={{ textAlign: 'center', pt: 15, pb: 15 }}>
          {loading ? (
            <Loader />
          ) : (
            hasMore && (
              <Box sx={{ mx: 12 }}>
                <CustomButton
                  variant="contained"
                  onClick={onSeeMoreClick}
                  fullWidth
                  aria-label="see more similar articles"
                  startIcon={<ExpandMore />}
                >
                  See More
                </CustomButton>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};
