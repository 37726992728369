import { Box } from '@mui/material';

import { useAlertsStore } from '../../../store/useAlertsStore';
import { AlertMessage } from '../AlertMessage/AlertMessage';

export function AlertMessages() {
  const alerts = useAlertsStore((state) => state.alerts);
  const closeAlert = useAlertsStore((state) => state.closeAlert);

  return (
    <Box id="alerts">
      {alerts.map((message) => (
        <AlertMessage key={message.id} onClose={() => closeAlert(message.id)} {...message} />
      ))}
    </Box>
  );
}
