import { SearchEntity } from '../lib/types';

export class SearchUtils {
  /**
   * Check if the user has reached their translation limit
   * @param request - the search request
   * @param response - the search response
   * @returns true if the user has reached their translation limit
   */
  static reachedTranslationLimit(response: SearchEntity) {
    if (!response?.computed.translationUsage) return false;

    return response.computed.translationUsage.uses === response.computed.translationUsage.limit;
  }

  /**
   * Check if the user's highlighted text has been truncated
   * @param response - the search response
   * @returns true if the user's highlighted text has been truncated
   */
  static highlightedTextTruncated(response: SearchEntity) {
    return response.computed.truncatedTexts?.[1]?.percentageAnalyzed < 100;
  }

  /**
   * Check if the document has been translated and is not in English
   * @param response - the search response
   * @returns true if the document has been translated and is not in English
   */
  static documentTranslated(response: SearchEntity) {
    return response?.meta.query_texts[0].translated;
  }

  /**
   * Check if the document is in English and thus does not need to be translated
   * @param response - the search response
   * @returns true if the document is in English and thus does not need to be translated
   */
  static documentInEnglish(response: SearchEntity) {
    if (!response) return true;
    return (
      !response.meta.query_texts[0].detected_language_name ||
      response.meta.query_texts[0].detected_language_name === 'English'
    );
  }
}
