import { Alert, AlertProps, Typography } from '@mui/material';

export interface AlertMessageProps extends AlertProps {
  id: string;
}

export const AlertMessage: React.FC<AlertMessageProps> = (props) => {
  const content = props.children || props.content;

  return (
    <Alert {...props} sx={{ m: 2, boxShadow: 'none', ...props?.sx }}>
      {typeof content === 'string' ? <Typography>{content}</Typography> : content}
    </Alert>
  );
};
