import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { RawAxiosRequestConfig } from 'axios';

import { axios } from '../../../lib/axios';
import { UserEntity } from '../../../lib/types';

/**
 * Send a request to get the user
 * @param config - Axios request config
 * @returns The user data
 */
export async function getUser(config?: RawAxiosRequestConfig) {
  try {
    const { data } = await axios.get<UserEntity | null>(`/api/auth`, config);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Get the user
 * @returns User query
 */
export function useGetUser(options?: Partial<UseQueryOptions<UserEntity | null>>) {
  return useQuery({
    queryKey: ['auth'],
    queryFn: () => getUser(),
    ...options,
  });
}
