import { useQuery } from '@tanstack/react-query';

import { axios } from '../../../lib/axios';
import { CitationStyleEnum } from '../../../lib/types';
import { WorkUtils } from '../../../utils/WorkUtils';

/**
 * Send a request to get citations for a list of works
 * @param workIds - The work IDs
 * @param style - The citation style
 * @returns The citations
 */
export async function getCitations(workIds: string[], style: CitationStyleEnum) {
  const { data } = await axios.get<string>(`/api/citations`, { params: { workIds, style } });
  return data;
}

/**
 * Get citations for a list of works
 * @returns Citations query
 */
export function useGetCitations(workIds: string[], style: CitationStyleEnum) {
  const cleanWorkIds = workIds?.map((workId) => WorkUtils.getId(workId));

  return useQuery({
    queryKey: ['citations', { workIds: cleanWorkIds, style }],
    queryFn: () => getCitations(cleanWorkIds, style),
    enabled: Boolean(cleanWorkIds?.length && style),
  });
}
