import { Box, Container } from '@mui/material';
import { useEffect, useRef } from 'react';

import { Color } from '../../lib/colors';
import { useUiStore } from '../../store/useUiStore';
import { Footer } from './Footer';

interface IProps {
  onMount?: (ref: HTMLDivElement) => void;
  children: React.ReactNode;
}

export const Scrollable: React.FC<IProps> = ({ onMount, children }) => {
  const addScrollableContainer = useUiStore((state) => state.addScrollableContainer);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    addScrollableContainer(ref.current);
    onMount && onMount(ref.current);
  }, [ref.current]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        background: Color.Grey,
      }}
      ref={ref}
    >
      <Container
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 auto',
          background: Color.Grey,
          padding: '0 !important',
        }}
      >
        {children}
      </Container>

      <Footer />
    </Box>
  );
};
