import { Lightbulb } from '@mui/icons-material';
import { Box, CardHeader, Typography } from '@mui/material';

import { Color } from '../../../lib/colors';
import { TopicWithTypeEntity } from '../../../lib/excover';
import { TopicStatus } from '../Topic/TopicCard';
import { TopicFilterButton } from '../Topic/TopicFilterButton';

interface IProps {
  topic: TopicWithTypeEntity;
  status: TopicStatus;
  onInclude: (topic: TopicWithTypeEntity) => void;
  onExclude: (topic: TopicWithTypeEntity) => void;
  onRemoveInclude: (topic: TopicWithTypeEntity) => void;
  onRemoveExclude: (topic: TopicWithTypeEntity) => void;
}

export const TopicHeader: React.FC<IProps> = ({
  topic,
  status,
  onInclude,
  onExclude,
  onRemoveInclude,
  onRemoveExclude,
}) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Lightbulb htmlColor={Color.Topic} fontSize="small" />
          <Typography variant="body2" color="secondary" sx={{ fontSize: 14, fontWeight: 'light', ml: 2 }}>
            Topic
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', padding: '4px 2px 2px 2px' }} onClick={(event) => event.stopPropagation()}>
          {/* <Tooltip title="Info">
            <IconButton onClick={() => {}} size="medium" style={{ padding: 2 }} aria-label="info">
              <InfoOutlined />
            </IconButton>
          </Tooltip> */}

          <TopicFilterButton
            status={status}
            onInclude={() => {
              if (status !== TopicStatus.Included) {
                onInclude?.(topic);
              } else {
                onRemoveInclude?.(topic);
              }
            }}
            onExclude={() => {
              if (status !== TopicStatus.Excluded) {
                onExclude?.(topic);
              } else {
                onRemoveExclude?.(topic);
              }
            }}
          />
        </Box>
      </Box>

      <CardHeader
        sx={{ p: 0 }}
        disableTypography
        title={
          <Typography style={{ lineHeight: 1.2 }} className="ph-no-capture">
            {topic?.display_name}
          </Typography>
        }
      />
    </>
  );
};
