export const sizes = {
  borderRadius: 2,
  cardSpacing: 3,
  cardRibbonFontSize: 12,
  cardRibbonFontWeight: 500,
  cardRibbonHeight: 26,
  cardTabMaxHeight: 250,
  cardPaneFontSize: '.9em',
  cardHeaderTitle: '.9rem',
  cardHeaderSubtitle: '.7rem',
  cardHeaderSubtitleLineHeight: '.85rem',
  sidebarWidth: 500,
};
