import { StripeSubscriptionStatusEnum } from '../types';

export class Subscription {
  id: string;
  // Stripe
  customerId: string | null;
  productId: string | null;
  priceId: string | null;
  subscriptionId: string | null;
  subscriptionStatus: StripeSubscriptionStatusEnum | null;
  // DateTime
  createdAt: Date;
  updatedAt: Date;
  cancellationDate: Date | null;

  /**
   * Check if a user's subscription is active
   * @returns Whether the user's subscription is active
   */
  isActive() {
    return [StripeSubscriptionStatusEnum.ACTIVE, StripeSubscriptionStatusEnum.TRIALING].includes(
      this.subscriptionStatus,
    );
  }

  /**
   * Check if a user's subscription is canceled
   * @returns Whether the user's subscription is canceled
   */
  isCanceled() {
    return Boolean(this.cancellationDate);
  }

  /**
   * Check if a user's subscription is past due
   * @returns Whether the user's subscription is past due
   */
  isPastDue() {
    return this.subscriptionStatus === StripeSubscriptionStatusEnum.PAST_DUE;
  }

  /**
   * Check if a user has access to Plus features
   * @returns Whether the user has access to Plus features
   */
  hasPlusAccess() {
    // Default implementation can be overridden by subclasses
    return this.isActive();
  }
}
