import { CardHeader as CardHeaderComponent, CardHeaderProps, styled } from '@mui/material';

export const CardHeader: React.FC<CardHeaderProps> = styled(CardHeaderComponent)(({ theme }) => ({
  padding: theme.spacing(3),
  '& .MuiCardHeader-title': {
    '& a': {
      textDecoration: 'none',
      fontWeight: 'normal',
    },
  },
}));
