import { CardContent as CardContentComponent, CardContentProps, styled } from '@mui/material';

export const CardContent: React.FC<CardContentProps> = styled(CardContentComponent)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: 0,
  '& .MuiTabs-root': {
    minHeight: 26,
    '& .MuiTab-root': {
      fontSize: 12,
      fontWeight: 500,
      minHeight: 26,

      '&:not(.Mui-selected)': {
        color: theme.palette.secondary.main,
      },
    },
    '& .MuiTabs-indicator': {
      height: 1,
    },
  },
}));
