import { Close } from '@mui/icons-material';
import { Box, Chip, ChipProps, styled } from '@mui/material';

import { Color } from '../../lib/colors';
import { DEFAULT_FILTERS } from '../../lib/constants';
import { ExcoverQueryDto } from '../../lib/excover';
import { OpenAccessIcon } from '../icons/OpenAccessIcon';

const FilterChip = styled((props: ChipProps) => (
  <Chip
    deleteIcon={
      <Close sx={{ fontSize: `1rem !important`, color: (theme) => `${theme.palette.action.active} !important` }} />
    }
    size="small"
    clickable
    {...props}
  />
))({
  margin: 2,
});

export interface IAppliedFiltersProps {
  query: ExcoverQueryDto;
  onRemoveHighlightedText: () => void;
  onRemoveDateRange: () => void;
  onRemoveCitationCount: () => void;
  onRemoveOpenAccess: () => void;
  children: React.ReactNode;
  loading?: boolean;
}

export const AppliedFilters: React.FC<IAppliedFiltersProps> = ({
  query,
  onRemoveHighlightedText,
  onRemoveDateRange,
  onRemoveCitationCount,
  onRemoveOpenAccess,
  children,
  loading,
}) => {
  return (
    <Box sx={{ mb: -1, ml: -2 }}>
      {children}

      {query?.query_texts?.[1]?.text && (
        <FilterChip
          disabled={loading}
          deleteIcon={<Close sx={{ fontSize: '1rem !important', color: `${Color.White} !important` }} />}
          className="ph-no-capture"
          label={`Highlighted "${query.query_texts[1]?.text}"`}
          color="secondary"
          onClick={onRemoveHighlightedText}
          onDelete={onRemoveHighlightedText}
        />
      )}

      {query?.filters &&
        (query.filters.publication_year_min !== DEFAULT_FILTERS.dateRange.from ||
          query.filters.publication_year_max !== DEFAULT_FILTERS.dateRange.to) && (
          <FilterChip
            disabled={loading}
            label={`From ${query.filters.publication_year_min} to ${query.filters.publication_year_max}`}
            onClick={onRemoveDateRange}
            onDelete={onRemoveDateRange}
          />
        )}

      {query?.filters &&
        typeof query.filters.cited_by_count_min !== 'undefined' &&
        typeof query.filters.cited_by_count_max === 'undefined' && (
          <FilterChip
            disabled={loading}
            label={`Citation count at least ${query.filters.cited_by_count_min}`}
            onClick={onRemoveCitationCount}
            onDelete={onRemoveCitationCount}
          />
        )}

      {query?.filters &&
        typeof query.filters.cited_by_count_min === 'undefined' &&
        typeof query.filters.cited_by_count_max !== 'undefined' && (
          <FilterChip
            disabled={loading}
            label={`Citation count at most ${query.filters.cited_by_count_max}`}
            onClick={onRemoveCitationCount}
            onDelete={onRemoveCitationCount}
          />
        )}

      {query?.filters &&
        typeof query.filters.cited_by_count_min !== 'undefined' &&
        typeof query.filters.cited_by_count_max !== 'undefined' && (
          <FilterChip
            disabled={loading}
            label={
              query.filters.cited_by_count_min === query.filters.cited_by_count_max
                ? `Citation count exactly ${query.filters.cited_by_count_min}`
                : `Citation count between ${query.filters.cited_by_count_min} and ${query.filters.cited_by_count_max}`
            }
            onClick={onRemoveCitationCount}
            onDelete={onRemoveCitationCount}
          />
        )}

      {query?.filters && query.filters.is_oa && (
        <FilterChip
          icon={<OpenAccessIcon color="action" sx={{ fontSize: '1rem' }} />}
          disabled={loading}
          label="Open Access"
          onClick={onRemoveOpenAccess}
          onDelete={onRemoveOpenAccess}
        />
      )}
    </Box>
  );
};
