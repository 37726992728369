import Axios from 'axios';

import { config } from './config';

export const axios = Axios.create({
  baseURL: typeof window !== 'undefined' ? config.API_URL : config.AXIOS_BASE_URL,

  // Send cookies to the API
  withCredentials: true,
});

// Always return a readable error message
axios.interceptors.response.use(undefined, (error) => {
  const status = error.response?.status || error.status;
  const message = error.response?.data?.message || error.message;

  if (error.code === 'ECONNREFUSED') {
    return false;
  }

  if (!message) {
    return Promise.reject(error);
  }

  return Promise.reject({ status, message });
});
