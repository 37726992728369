import { ArrowForward, Close } from '@mui/icons-material';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { config } from '../../../lib/config';
import { WebsiteNavigation } from '../../../lib/navigation';
import { ExternalLink } from '../../common/ExternalLink';
import { CustomButton } from '../../new/CustomButton';
import { CustomDialog } from '../../new/CustomDialog';

interface IProps {
  open: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

export const PrivacyNoticeDialog: React.FC<IProps> = ({ open, onCancel, onContinue }) => {
  return (
    <CustomDialog open={open} onClose={onCancel}>
      <DialogTitle component="div">
        <Typography variant="h4" sx={{ mt: '12px' }}>
          Privacy Notice
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography>
          Doing a search on Keenious requires sending the full text of your document to our servers in the EU. Keenious
          does not store any of your text used, and the text is purely used to give you the most relevant results. For
          more information about how we use and collect data please see our{' '}
          <ExternalLink href={`${config.WEBSITE_URL}${WebsiteNavigation.PrivacyPolicy}`} title="Privacy Policy">
            Privacy Policy
          </ExternalLink>
          .
        </Typography>
      </DialogContent>

      <DialogActions>
        <CustomButton onClick={onCancel} aria-label="cancel" variant="text" startIcon={<Close />}>
          Cancel
        </CustomButton>

        <CustomButton
          sx={{ ml: 0 }}
          onClick={onContinue}
          aria-label="continue"
          id="privacy-notice-continue"
          endIcon={<ArrowForward />}
        >
          Accept
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};
