import { IndividualSubscriptionEntity, UserEntity } from '../types';
import { Subscription } from './Subscription';

export class IndividualSubscription extends Subscription {
  // User
  user?: UserEntity;
  userId: string;

  constructor(subscription: IndividualSubscriptionEntity) {
    super();

    // Copy all properties from the subscription object to this instance
    Object.assign(this, subscription);
  }
}
