import { useEntitiesStore } from '@keenious/libraries/store/useEntitiesStore';
import { EntityEnum } from '@keenious/libraries/types/entities';

import { PublicationPageContainer } from '../../containers/publication/PublicationPage/PublicationPageContainer';
import { TopicPageContainer } from '../../containers/topics/TopicPage/TopicPageContainer';
import { EntityWrapper } from './EntityWrapper';

export const Entities: React.FC = () => {
  const entities = useEntitiesStore((state) => state.entities);

  return (
    <div id="entities" style={{ zIndex: 600 }}>
      {entities.map((entity) => {
        switch (entity.type) {
          case EntityEnum.Publication:
            return (
              <EntityWrapper key={entity.id} entity={entity}>
                <PublicationPageContainer id={entity.id} />
              </EntityWrapper>
            );
          case EntityEnum.Topic:
            return (
              <EntityWrapper key={entity.id} entity={entity}>
                <TopicPageContainer id={entity.id} />
              </EntityWrapper>
            );
          case EntityEnum.Generic:
            return (
              <EntityWrapper key={entity.id} entity={entity}>
                {entity.getTemplate()}
              </EntityWrapper>
            );
        }
      })}
    </div>
  );
};
