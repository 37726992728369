import { useInfiniteQuery } from '@tanstack/react-query';
import { RawAxiosRequestConfig } from 'axios';

import { axios } from '../../../lib/axios';
import { ExcoverQueryEntity, ExcoverSimilarDto } from '../../../lib/excover';
import { WorkUtils } from '../../../utils/WorkUtils';

const LIMIT = 10;

/**
 * Send a request to get similar works by a work's ID
 * @param query - The work's ID and pagination
 * @param config - Axios request config
 * @returns The work data
 */
export async function getSimilarWorks(workId: string, offset = 0, config?: RawAxiosRequestConfig) {
  const params: ExcoverSimilarDto = { offset, limit: LIMIT };
  const { data } = await axios.get<ExcoverQueryEntity | null>(`/api/works/${workId}/similar`, { ...config, params });
  return data;
}

/**
 * Get similar works by a work's ID
 * @returns Similar works query
 */
export function useGetSimilarWorks(workId: string): ReturnType<typeof useInfiniteQuery<ExcoverQueryEntity>> {
  return useInfiniteQuery({
    queryKey: ['works', WorkUtils.getId(workId), 'similar'],
    queryFn: ({ pageParam }) => getSimilarWorks(WorkUtils.getId(workId), pageParam * LIMIT),
    enabled: Boolean(workId),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage) return undefined;
      const hasMore = lastPage.meta.total_hits > lastPage.meta.offset + lastPage.meta.count;
      return hasMore ? allPages.length : undefined;
    },
  });
}
