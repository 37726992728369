import { FilterAlt, FilterAltOff, FilterList, FilterListOff } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { TopicStatus } from './TopicCard';

interface IProps {
  loading?: boolean;
  status?: TopicStatus;
  onInclude?: () => void;
  onExclude?: () => void;
}

export const TopicFilterButton: React.FC<IProps> = ({ loading, status, onInclude, onExclude }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'topicCardFilter' : undefined;
  const isIncluded = status === TopicStatus.Included;
  const isExcluded = status === TopicStatus.Excluded;

  return (
    <>
      <Tooltip title="Filter results by this topic">
        <span>
          <IconButton
            aria-label="Filter results by this topic"
            disabled={loading}
            size="small"
            style={{ padding: 2 }}
            onClick={handleClick}
          >
            <FilterList
              sx={{
                fontSize: 22,
                color: (theme) =>
                  isIncluded || isExcluded ? theme.palette.primary.main : theme.palette.secondary.light,
              }}
            />
          </IconButton>
        </span>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          zIndex: 9999,
          '& .MuiPopover-paper': {
            borderRadius: 2,
          },
        }}
      >
        <List disablePadding>
          <ListItemButton
            sx={{ py: 3, px: 4 }}
            onClick={() => {
              onInclude();
              handleClose();
            }}
            selected={isIncluded}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              {isIncluded ? <FilterAltOff fontSize="small" /> : <FilterAlt fontSize="small" />}
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">{isIncluded ? 'Remove from' : 'Add to'} Include List</Typography>
            </ListItemText>
          </ListItemButton>

          <ListItemButton
            sx={{ py: 3, px: 4 }}
            onClick={() => {
              onExclude();
              handleClose();
            }}
            selected={isExcluded}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              {isExcluded ? <FilterListOff fontSize="small" /> : <FilterList fontSize="small" />}
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">{isExcluded ? 'Remove from' : 'Add to'} Exclude List</Typography>
            </ListItemText>
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
};
