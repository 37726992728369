import { AppBar, Toolbar, ToolbarProps } from '@mui/material';

import { Color } from '@keenious/libraries/lib/colors';

export const TopBar: React.FC<ToolbarProps> = ({ children, ...props }) => {
  return (
    <AppBar position="static" sx={{ boxShadow: 'none', background: Color.Grey }}>
      <Toolbar
        sx={{
          minHeight: 50,
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: (theme) => theme.spacing(0, 6),
          color: (theme) => theme.palette.secondary.main,
          ...props?.sx,
        }}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
};
