import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { axios } from '../../../lib/axios';
import { BookmarkEntity } from '../../../lib/types';
import { WorkUtils } from '../../../utils/WorkUtils';

/**
 * Send a request to create bookmarks
 * @param workIds - The works' IDs
 * @returns The created bookmarks
 */
async function createBookmarks(workIds: string[]) {
  const { data } = await axios.post<BookmarkEntity[]>(`/api/bookmarks`, {
    workIds: workIds.map((workId) => WorkUtils.getId(workId)),
  });
  return data;
}

/**
 * Request a list of bookmarks to be created
 * @returns Create bookmarks mutation
 */
export function useCreateBookmarks() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createBookmarks,
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['bookmarks'] });
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });
}
