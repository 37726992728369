import { ITopicProps, Topic } from '@keenious/libraries/components/topics/Topic/Topic';
import { TopicChipState } from '@keenious/libraries/components/topics/TopicChip/TopicChip';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useUiStore } from '@keenious/libraries/store/useUiStore';

import { TopicCardContainer } from './TopicCardContainer';

export const TopicContainer: React.FC<ITopicProps> = (props) => {
  const loading = useUiStore((state) => state.loading);
  const isIncludedTopic = useFiltersStore((state) => state.isIncludedTopic);
  const isExcludedTopic = useFiltersStore((state) => state.isExcludedTopic);

  return (
    <Topic
      TooltipProps={{
        onOpen: () => {
          if (!props.topic) return;
          AnalyticsUtils.captureEvent('Topics: Viewed Topic Preview', {
            name: props.topic.display_name,
            type: props.topic.type,
          });
        },
      }}
      {...props}
      TopicChipProps={{
        disabled: loading,
        state: isIncludedTopic(props.topic?.id)
          ? TopicChipState.Included
          : isExcludedTopic(props.topic?.id)
            ? TopicChipState.Excluded
            : TopicChipState.Suggested,
        ...props.TopicChipProps,
      }}
    >
      <TopicCardContainer topic={props.topic} />
    </Topic>
  );
};
