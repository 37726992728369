import {
  IPublicationCardProps,
  PublicationCard,
} from '@keenious/libraries/components/publication/PublicationCard/PublicationCard';
import { WorkEntity, WorkMetaEntity } from '@keenious/libraries/lib/excover';

import { PublicationBookmarkButton } from './PublicationBookmarkButton';

interface IProps extends IPublicationCardProps {
  work: WorkEntity;
  meta: WorkMetaEntity;
}

export const PublicationCardContainer: React.FC<IProps> = ({ work, meta, ...props }) => {
  return (
    <PublicationCard
      key={work.id}
      title={work.title}
      work={work}
      meta={meta}
      PublicationBookmarkButtonComponent={<PublicationBookmarkButton work={work} />}
      {...props}
    />
  );
};
