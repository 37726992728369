import { Close, ExpandMore, FilterList, InfoOutlined } from '@mui/icons-material';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { Loader } from '@keenious/libraries/components/common/Loader/Loader';
import { CustomButton } from '@keenious/libraries/components/new/CustomButton';
import { PublicationCard } from '@keenious/libraries/components/publication/PublicationCard/PublicationCard';
import { TopicPage } from '@keenious/libraries/components/topics/TopicPage/TopicPage';
import { useEffectAsync } from '@keenious/libraries/hooks/useEffectAsync';
import { Color } from '@keenious/libraries/lib/colors';
import { ExcoverQueryEntity } from '@keenious/libraries/lib/excover';
import { useEntitiesStore } from '@keenious/libraries/store/useEntitiesStore';
import { useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useTopicsStore } from '@keenious/libraries/store/useTopicsStore';
import { useUiStore } from '@keenious/libraries/store/useUiStore';
import { EntityTab } from '@keenious/libraries/types/entities';

import { useSearchStore } from '../../../store/useSearchStore';
import { PublicationBookmarkButton } from '../../publication/PublicationBookmarkButton';
import { PublicationCardContainer } from '../../publication/PublicationCardContainer';
import { TopicHeaderContainer } from './TopicHeaderContainer';

interface IProps {
  id: string;
}

export const TopicPageContainer: React.FC<IProps> = ({ id }) => {
  const [applyFilters, setApplyFilters] = useState(true);
  const [articles, setArticles] = useState<ExcoverQueryEntity['hits']>();
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const getTopicById = useTopicsStore((state) => state.getTopicById);
  const getWorksByTopic = useSearchStore((state) => state.getWorksByTopic);
  const setEntityTab = useUiStore((state) => state.setEntityTab);
  const closeAll = useEntitiesStore((state) => state.closeAll);
  const filtersChanged = useFiltersStore((state) => state.filtersChanged);
  const includeTopic = useFiltersStore((state) => state.includeTopic);
  const resetAndApplySearch = useSearchStore((state) => state.resetAndApplySearch);

  const topic = useMemo(() => getTopicById(id), [id]);

  useEffectAsync(async () => {
    if (!id) return;
    setLoading(true);
    const response = await getWorksByTopic(id, applyFilters, { limit: 10, offset: 0 });
    setLoading(false);
    if (!response) return;
    setArticles(response.hits);
    setHasMore(response.meta.total_hits > response.meta.offset + response.meta.count);
  }, [id, applyFilters]);

  async function handleSeeMore() {
    setLoading(true);
    const response = await getWorksByTopic(id, applyFilters, { limit: 10, offset: articles.length });
    setLoading(false);
    if (!response) return;
    setArticles([...articles, ...response.hits]);
    setHasMore(response.meta.total_hits > response.meta.offset + response.meta.count);
  }

  return (
    <>
      <TopicPage topic={topic} TopicHeaderComponent={<TopicHeaderContainer topic={topic} />} />

      <Box
        sx={{
          background: Color.Purple,
          color: Color.White,
          p: 6,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontSize: 17, fontWeight: 500, mr: 4 }}>Relevant Topic Articles</Typography>

        <Tooltip title="Relevant articles to your document that are tagged with this topic">
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </Box>

      <Box sx={{ background: Color.Grey }}>
        {filtersChanged() && (
          <Chip
            clickable
            icon={
              applyFilters ? (
                <Close sx={{ fontSize: '1rem !important', color: `${Color.White} !important` }} />
              ) : (
                <FilterList sx={{ fontSize: '15px !important' }} />
              )
            }
            label="Apply current filters"
            size="small"
            color="primary"
            variant={applyFilters ? 'filled' : 'outlined'}
            sx={{ m: 4 }}
            onClick={() => setApplyFilters(!applyFilters)}
          />
        )}

        {articles ? (
          articles.length > 0 ? (
            articles.map((hit) => (
              <PublicationCardContainer key={hit.entity.id} work={hit.entity} meta={hit.meta} disableResultInsights />
            ))
          ) : (
            <Box sx={{ p: 6 }}>
              <Typography variant="h4">No results found</Typography>
              <Typography>Sorry, we could not find any articles matching your filtering requirements.</Typography>
            </Box>
          )
        ) : (
          [...Array(10)].map((item, index) => (
            <PublicationCard
              key={index}
              PublicationBookmarkButtonComponent={<PublicationBookmarkButton />}
              disableResultInsights
            />
          ))
        )}

        <Box sx={{ textAlign: 'center', pt: 15, pb: 15 }}>
          {loading ? (
            <Box sx={{ my: 8 }}>
              <Loader />
            </Box>
          ) : (
            hasMore && (
              <Box sx={{ mx: 12 }}>
                <CustomButton
                  variant="contained"
                  onClick={() => {
                    includeTopic(topic.id);
                    closeAll();
                    setEntityTab(EntityTab.Articles);
                    resetAndApplySearch();
                  }}
                  fullWidth
                  aria-label="see more similar articles"
                  startIcon={<FilterList />}
                  sx={{ mb: 6 }}
                >
                  Filter by this topic
                </CustomButton>

                <CustomButton
                  variant="outlined"
                  onClick={handleSeeMore}
                  fullWidth
                  aria-label="see more similar articles"
                  startIcon={<ExpandMore />}
                >
                  See More
                </CustomButton>
              </Box>
            )
          )}
        </Box>
      </Box>
    </>
  );
};
