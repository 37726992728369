import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import { Color } from '../../lib/colors';
import { ExternalNavigation } from '../../lib/navigation';
import { ExternalLink } from '../common/ExternalLink';

interface IProps {
  onCancel: () => void;
}

export const HighlightedTextTidbit: React.FC<IProps> = ({ onCancel }) => {
  return (
    <Box sx={{ pl: 6, pr: 2, pb: 8, background: Color.DarkGrey, color: Color.White, borderRadius: '0 0 8px 8px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography>
          <strong>Highlighting Text</strong>
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            sx={{ mb: 4, '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}
            size="small"
            color="inherit"
            onClick={onCancel}
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      <Typography variant="body2">
        Your highlighted text will influence your results more than the rest of the document.{' '}
        <ExternalLink href={ExternalNavigation.HowToSearch} underline="always" color="inherit">
          Learn more
        </ExternalLink>
        .
      </Typography>
    </Box>
  );
};
