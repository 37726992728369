import { SourceTypeEnum, WorkEntity } from '../lib/excover';
import { StringUtils } from './StringUtils';

// Helper function to safely get a nested value
function getNestedValueSafely(obj: WorkEntity, path: string): unknown {
  return path?.split('.').reduce((acc, part) => {
    return acc !== undefined && acc !== null ? acc[part] : undefined;
  }, obj);
}

// Function to URL encode a string
function urlEncode(value: string): string {
  return encodeURIComponent(value);
}

// Function to Base64 encode characters that are not URL safe and then URL encode the result
function urlEncodeSafe(value: string): string {
  return urlEncode(
    value
      .replace(/=/g, window.btoa('='))
      .replace(/\//g, window.btoa('/'))
      .replace(/\\/g, window.btoa('\\'))
      .replace(/\?/g, window.btoa('?')),
  );
}

// Main parser function to replace placeholders in text using the WorkEntity properties
function parseTemplate(template: string, work: WorkEntity): string {
  // Use a regular expression to find all placeholders in the template
  return template.replace(/{{(.*?)}}/g, (match, propertyWithFormat) => {
    // Split the property and format
    const [property, format] = propertyWithFormat.split(':');

    if (format && !['encode', 'encodesafe'].includes(format)) {
      return match;
    }

    // Attempt to retrieve the nested value
    const value = getNestedValueSafely(work, property);

    // If the value is undefined, return the original match
    if (value === undefined) {
      return match;
    }

    // Apply encoding if required
    let encodedValue = String(value);
    switch (format) {
      case 'encode':
        encodedValue = urlEncode(encodedValue);
        break;
      case 'encodesafe':
        encodedValue = urlEncodeSafe(encodedValue);
        break;
    }

    // Return the encoded value
    return encodedValue;
  });
}

export class WorkUtils {
  static DEFAULT_LINK_RESOLVER = 'https://libkey.io/';

  /**
   * Get the readable type of the work
   * @param work - The work entity
   * @returns the readable type of the work
   */
  static getReadableType(work: WorkEntity) {
    if (work.primary_location?.source?.type === SourceTypeEnum.REPOSITORY) {
      return 'Repository Article';
    }

    if (work.primary_location?.source?.type === SourceTypeEnum.JOURNAL) {
      return 'Journal Article';
    }

    return work.type
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  static getId(workId?: string) {
    if (!workId) return '';
    return workId.replace('https://openalex.org/', '');
  }

  /**
   * Check if work has pdf
   * @param work - The work entity
   * @returns boolean
   */
  static hasPdf(work: WorkEntity) {
    return Boolean(work.open_access.oa_url);
  }

  /**
   * Get URL to use for PDF
   * @param work - The work entity
   * @returns string
   */
  static getTitleLinkUrl(work: WorkEntity) {
    const pdfUrl = work.open_access.oa_url;
    if (pdfUrl) {
      return pdfUrl;
    }

    if (!work.doi) {
      return null;
    }

    return work.doi;
  }

  /**
   * Get link resolved URL of work
   * @param work - The work entity
   * @param baseUrl - The base URL to use for link resolution
   * @returns string
   */
  static getUrl(work: WorkEntity, baseUrl: string) {
    if (!baseUrl) baseUrl = WorkUtils.DEFAULT_LINK_RESOLVER;

    const doi = work?.doi.replace('https://doi.org/', '');

    // Add utm_source=Keenious to libkey links
    const isLibKey = baseUrl === WorkUtils.DEFAULT_LINK_RESOLVER;
    if (isLibKey) {
      const utmSource = '?utm_source=Keenious';
      return baseUrl + doi + utmSource;
    }

    // If the baseUrl uses the template syntax, replace the template with the doi of the work
    if (baseUrl.match(/{{.*}}/)) {
      let url = baseUrl;

      // Replace placeholders with work properties
      url = parseTemplate(url, { ...work, doi });

      return url;
    }

    // Add doi to other links
    return baseUrl + doi;
  }

  /**
   * Compute shortened title
   * @param work - The work entity
   * @returns string
   */
  static getShortenedTitle(work: WorkEntity) {
    return StringUtils.fitToLength(work.title, 150, true);
  }

  /**
   * Get work publishing year
   * @param work - The work entity
   * @returns year
   */
  static getYear(work: WorkEntity) {
    if (!work?.publication_year) return '';
    return String(work.publication_year);
  }
}
