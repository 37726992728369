import { ArticleOutlined, BarChart, FormatQuote } from '@mui/icons-material';
import { Box, CardActionArea, Grid, IconButton, Link, Skeleton, Tooltip, Typography, darken } from '@mui/material';
import { useEffect, useState } from 'react';

import { AnalyticsUtils } from '../../../lib/analytics';
import { Color } from '../../../lib/colors';
import { WorkEntity, WorkMetaEntity } from '../../../lib/excover';
import { HighlightedWord, highlightKeywords } from '../../../lib/highlight-keywords';
import { useEntitiesStore } from '../../../store/useEntitiesStore';
import { useFiltersStore } from '../../../store/useFiltersStore';
import { EntityEnum } from '../../../types/entities';
import { LocalStorageUtils } from '../../../utils/LocalStorageUtils';
import { MathUtils } from '../../../utils/MathUtils';
import { StringUtils } from '../../../utils/StringUtils';
import { WorkUtils } from '../../../utils/WorkUtils';
import { Card } from '../../card/Card/Card';
import { CardContent } from '../../card/CardContent/CardContent';
import { CardHeader } from '../../card/CardHeader/CardHeader';
import { CitationDialog } from '../../citations/CitationDialog/CitationDialog';
import { OpenAccessIcon } from '../../icons/OpenAccessIcon';
import { ResultInsightsDialog } from '../ResultInsightsDialog/ResultInsightsDialog';

export interface IPublicationCardProps {
  title?: string;
  work?: WorkEntity;
  meta?: WorkMetaEntity;
  disableResultInsights?: boolean;
  PublicationBookmarkButtonComponent?: React.ReactNode;
}

export const PublicationCard: React.FC<IPublicationCardProps> = ({
  title,
  work,
  meta,
  disableResultInsights,
  PublicationBookmarkButtonComponent,
}) => {
  const open = useEntitiesStore((state) => state.open);
  const queryString = useFiltersStore((state) => state.queryString);

  const [citationDialogOpen, setCitationDialogOpen] = useState(false);
  const [resultInsightsDialogOpen, setResultInsightsDialogOpen] = useState(false);
  const [titleParts, setTitleParts] = useState<HighlightedWord[]>([]);
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    if (!work) return;
    refreshVisited();
  }, [work]);

  function refreshVisited() {
    setTimeout(() => {
      const visitedPublications = LocalStorageUtils.get<string[]>('visitedPublications') || [];
      work && setVisited(visitedPublications.includes(WorkUtils.getId(work?.id)));
    }, 0);
  }

  useEffect(() => {
    if (!title || !queryString) return;
    setTitleParts(highlightKeywords(title, queryString));
  }, []);

  return (
    <>
      <CardActionArea
        component="div"
        disableRipple
        onClick={(event) => {
          event.stopPropagation();
          open({
            id: String(work.id),
            type: EntityEnum.Publication,
            title: WorkUtils.getReadableType(work),
          });
        }}
        sx={{ userSelect: 'auto' }}
      >
        <Card
          data-testid={'SearchResultCard'}
          sx={{
            borderTop: `2px solid transparent`,
            borderBottom: `2px solid transparent`,
            marginBottom: 3,
            transition: 'border-top .25s, border-bottom .25s',
            borderLeft: 0,
            borderRadius: 0,
            '& .MuiLink-root': { '&:visited': { color: Color.VisitedLink } },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ArticleOutlined htmlColor={Color.Publication} fontSize="small" />

              <Typography variant="body2" color="secondary" sx={{ fontSize: 13, fontWeight: 'light', ml: 2 }}>
                {work ? WorkUtils.getReadableType(work) : <Skeleton width={MathUtils.getRandomBetween(80, 120)} />}
              </Typography>

              {work && WorkUtils.hasPdf(work) && (
                <Tooltip title="Open Access">
                  <Box component="span" sx={{ display: 'flex' }}>
                    <OpenAccessIcon color="action" sx={{ ml: 2, fontSize: '1rem' }} />
                  </Box>
                </Tooltip>
              )}
            </Box>

            <Box sx={{ display: 'flex', padding: '4px 2px 2px 2px' }} onClick={(event) => event.stopPropagation()}>
              {!disableResultInsights && (
                <Tooltip title="Result Insights">
                  <span>
                    <IconButton
                      aria-label="Result Insights"
                      disabled={!work || disableResultInsights}
                      size="small"
                      style={{ padding: 2 }}
                      onClick={() => {
                        setResultInsightsDialogOpen(true);
                        AnalyticsUtils.captureEvent('Articles: Clicked "Result Insights" Button');
                      }}
                    >
                      <BarChart sx={{ fontSize: 22 }} />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              <Tooltip title="Cite Publication">
                <span>
                  <IconButton
                    aria-label="Cite Publication"
                    disabled={!work}
                    size="small"
                    style={{ padding: 2 }}
                    onClick={() => {
                      setCitationDialogOpen(true);
                      AnalyticsUtils.captureEvent('Citations: Clicked "Cite Publication" Button');
                    }}
                  >
                    <FormatQuote sx={{ fontSize: 22 }} />
                  </IconButton>
                </span>
              </Tooltip>

              {PublicationBookmarkButtonComponent}
            </Box>
          </Box>

          <CardHeader
            sx={{ p: 0 }}
            disableTypography
            title={
              work ? (
                <Link
                  style={{ color: visited ? Color.VisitedLink : darken(Color.Link, 0.2), textDecoration: 'none' }}
                  onClick={refreshVisited}
                  title="Open Publication"
                >
                  <Typography style={{ lineHeight: 1.2 }} className="ph-no-capture">
                    {titleParts.length ? (
                      titleParts.map((part, index) => (
                        <span
                          key={index}
                          style={{ fontWeight: part.bold ? 700 : 400 }}
                          dangerouslySetInnerHTML={{
                            __html: StringUtils.removeNonGlyphCharacters(StringUtils.sanitize(part.text)),
                          }}
                        />
                      ))
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: StringUtils.removeNonGlyphCharacters(StringUtils.sanitize(title)),
                        }}
                      />
                    )}
                  </Typography>
                </Link>
              ) : (
                <Skeleton width="100%" height={38} sx={{ transform: 'scale(1)' }} />
              )
            }
            subheader={
              <Typography
                variant="caption"
                display="block"
                color="secondary"
                sx={{ fontSize: 11 }}
                className="ph-no-capture"
              >
                {work ? (
                  <>
                    <strong className="work-published-date">{work.publication_year}</strong>{' '}
                    {StringUtils.removeNonGlyphCharacters(work.primary_location_name)}
                  </>
                ) : (
                  <Skeleton width={MathUtils.getRandomBetween(100, 200)} />
                )}
              </Typography>
            }
          />

          <CardContent sx={{ p: 0, pb: '0 !important' }}>
            <div>
              <Grid container spacing={2} sx={{ my: 1 }}>
                <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="caption" color="secondary" sx={{ fontSize: 13 }} className="ph-no-capture">
                    {work ? (
                      `${work.first_author_name || ''}${work.authorships_count > 1 ? ' et al.' : ''}`
                    ) : (
                      <Skeleton width={MathUtils.getRandomBetween(50, 100)} />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Typography variant="caption" color="secondary" sx={{ fontSize: 13 }}>
                    {work ? (
                      `${work.cited_by_count} Citations`
                    ) : (
                      <Skeleton width={MathUtils.getRandomBetween(80, 100)} />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </CardActionArea>

      {work && citationDialogOpen && (
        <CitationDialog works={[work]} open={citationDialogOpen} onClose={() => setCitationDialogOpen(false)} />
      )}

      {work && meta && !disableResultInsights && resultInsightsDialogOpen && (
        <ResultInsightsDialog
          work={work}
          meta={meta}
          open={resultInsightsDialogOpen}
          onClose={() => setResultInsightsDialogOpen(false)}
        />
      )}
    </>
  );
};
