import { Box, CircularProgress } from '@mui/material';

import KeeniousSymbol from '../../../assets/keenious-symbol.svg';
import { Color } from '../../../lib/colors';

export const Loader: React.FC = () => {
  return (
    <Box sx={{ textAlign: 'center', position: 'relative' }}>
      <KeeniousSymbol
        color={Color.Purple}
        height={42}
        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -60%)' }}
      />
      <CircularProgress disableShrink size="5rem" />
    </Box>
  );
};
