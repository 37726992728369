import SwipeableViews from 'react-swipeable-views';

import { AnalyticsUtils } from '../../../lib/analytics';
import { useUiStore } from '../../../store/useUiStore';
import { EntityTab } from '../../../types/entities';
import { TabPanel } from '../../common/TabPanel';

interface IProps {
  SearchResultsComponent: React.ReactNode;
  TopicsTabPanelComponent: React.ReactNode;
}

export const EntityTabPanels: React.FC<IProps> = ({ SearchResultsComponent, TopicsTabPanelComponent }) => {
  const entityTab = useUiStore((state) => state.entityTab);
  const setEntityTab = useUiStore((state) => state.setEntityTab);

  return (
    <SwipeableViews
      index={entityTab}
      onChangeIndex={(value) => {
        setEntityTab(value);
        if (value === EntityTab.Topics) AnalyticsUtils.captureEvent('Tabs: Clicked "Articles" Tab');
        if (value === EntityTab.Articles) AnalyticsUtils.captureEvent('Tabs: Clicked "Topics" Tab');
      }}
    >
      <TabPanel value={entityTab} index={EntityTab.Articles}>
        {SearchResultsComponent}
      </TabPanel>

      <TabPanel value={entityTab} index={EntityTab.Topics}>
        {TopicsTabPanelComponent}
      </TabPanel>
    </SwipeableViews>
  );
};
