import { config } from '../lib/config';

export class EnvironmentUtils {
  static HOST_PRODUCTION = 'https://keenious.com';
  static HOST_STAGING = 'https://staging.keenious.com';
  static HOST_BETA = 'https://beta.keenious.com';
  static HOST_LOCAL = 'http://localhost:3000';

  /**
   * Check if the process is running on production
   * @returns boolean
   */
  static isProduction() {
    return config.WEBSITE_URL === EnvironmentUtils.HOST_PRODUCTION;
  }

  /**
   * Check if the process is running on staging
   * @returns boolean
   */
  static isStaging() {
    return config.WEBSITE_URL === EnvironmentUtils.HOST_STAGING;
  }

  /**
   * Check if the process is running on the beta environment
   * @returns boolean
   */
  static isBeta() {
    return config.WEBSITE_URL === EnvironmentUtils.HOST_BETA;
  }

  /**
   * Check if the process is running on local
   * @returns boolean
   */
  static isLocal() {
    return config.WEBSITE_URL === EnvironmentUtils.HOST_LOCAL;
  }
}
