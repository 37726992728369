import { Card as CardComponent, CardProps, styled } from '@mui/material';

export const Card: React.FC<CardProps> = styled(CardComponent)(({ theme }) => ({
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  padding: '2px 8px 0px 8px',
  marginBottom: theme.spacing(3),
  border: `1px solid #ebedef`,
  borderLeft: 'none',
  borderRight: 'none',
}));
