import { PublicationTopics } from '@keenious/libraries/components/publication/PublicationTopics/PublicationTopics';
import { TopicWithTypeEntity } from '@keenious/libraries/lib/excover';

import { TopicContainer } from '../../topics/TopicContainer';

interface IProps {
  topics?: TopicWithTypeEntity[];
  children?: React.ReactNode;
}

export interface ITopicsProps {
  onTopicClick: (topic: TopicWithTypeEntity) => void;
}

export const PublicationTopicsContainer: React.FC<IProps & ITopicsProps> = ({ topics, onTopicClick }) => {
  return (
    <PublicationTopics topics={topics}>
      {topics?.map((topic) => (
        <TopicContainer
          key={topic.id}
          topic={topic}
          TopicChipProps={{
            size: 'small',
            sx: { m: 1, pl: 1 },
            onClick: () => onTopicClick(topic),
          }}
        />
      ))}
    </PublicationTopics>
  );
};
