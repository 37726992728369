import { useQuery } from '@tanstack/react-query';
import { RawAxiosRequestConfig } from 'axios';

import { axios } from '../../../lib/axios';
import { InstitutionEntity } from '../../../lib/types';

/**
 * Send a request to get the user's institution by IP
 * @param config - Axios request config
 * @returns The institution by IP data
 */
export async function getInstitutionByIp(config?: RawAxiosRequestConfig) {
  try {
    const { data } = await axios.get<InstitutionEntity>(`/api/institutions/ip`, config);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Get the institution by IP
 * @returns institution by IP query
 */
export function useGetInstitutionByIp() {
  return useQuery({
    queryKey: ['institutions', 'ip'],
    queryFn: () => getInstitutionByIp(),
  });
}
