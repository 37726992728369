import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { axios } from '../../../lib/axios';
import { UserEntity } from '../../../lib/types';

/**
 * Send a request to update the user
 * @param user - The user to update with
 * @returns The updated user
 */
async function updateUser(user: Partial<UserEntity>) {
  const { data } = await axios.put<UserEntity>(`/api/auth`, user);
  return data;
}

/**
 * Update user
 * @returns User update mutation
 */
export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUser,
    // When mutate is called:
    onMutate: async (newUser) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['auth'] });

      // Snapshot the previous value
      const previousUser = queryClient.getQueryData(['auth']);

      // Optimistically update to the new value
      queryClient.setQueryData(['auth'], newUser);

      // Return a context object with the snapshotted value
      return { previousUser };
    },
    onError: (error: Error, newUser, context) => {
      if (error) {
        toast.error(error.message);
      }
      queryClient.setQueryData(['auth'], context.previousUser);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['auth'] });
    },
  });
}
