import { Tune } from '@mui/icons-material';
import { Box, Checkbox, Chip, Divider, FormControlLabel, Popover } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useNavigate } from 'react-router-dom';

import { CitationCountFilter } from '@keenious/libraries/components/filters/CitationCountFilter';
import { ClearFiltersButton } from '@keenious/libraries/components/filters/ClearFiltersButton';
import { YearFilter } from '@keenious/libraries/components/filters/YearFilter';
import { OpenAccessIcon } from '@keenious/libraries/components/icons/OpenAccessIcon';
import { CustomButton } from '@keenious/libraries/components/new/CustomButton';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { AddonNavigation } from '@keenious/libraries/lib/navigation';
import { useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useUiStore } from '@keenious/libraries/store/useUiStore';

import { useSearchStore } from '../../store/useSearchStore';

export const FiltersContainer: React.FC = () => {
  const navigate = useNavigate();

  const loading = useUiStore((state) => state.loading);
  const filters = useFiltersStore((state) => state.filters);
  const updateFilters = useFiltersStore((state) => state.updateFilters);
  const filtersChanged = useFiltersStore((state) => state.filtersChanged);
  const resetFilters = useFiltersStore((state) => state.resetFilters);
  const resetAndApplySearch = useSearchStore((state) => state.resetAndApplySearch);

  function handleSearch() {
    if (loading) return;
    navigate(AddonNavigation.Result);
    resetAndApplySearch();
  }

  function handleClear() {
    resetFilters();
    handleSearch();
    AnalyticsUtils.captureEvent('Filters: Cleared Filters');
  }

  return (
    <PopupState variant="popover" popupId="demoMenu">
      {(popupState) => (
        <>
          <Chip
            clickable
            disabled={loading}
            icon={<Tune sx={{ fontSize: '1rem !important' }} />}
            label="Filters"
            size="small"
            variant="outlined"
            sx={{ border: 'none' }}
            {...bindTrigger(popupState)}
          />

          <Popover
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            sx={{
              '& .MuiPopover-paper': {
                maxWidth: 260,
                borderRadius: 2,
                p: 1,
              },
            }}
            {...bindPopover(popupState)}
          >
            <YearFilter onEnter={handleSearch} />

            <Divider />

            <CitationCountFilter onEnter={handleSearch} />

            <Divider />

            <FormControlLabel
              sx={{ mx: 0 }}
              control={
                <Checkbox
                  size="small"
                  checked={filters.is_oa}
                  onChange={(event) => {
                    updateFilters({ is_oa: event.target.checked });
                    AnalyticsUtils.captureEvent('Filters: Changed "Open Access Only" Filter', {
                      value: event.target.checked,
                    });
                  }}
                />
              }
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <span>Open Access Only</span>
                  <OpenAccessIcon color="action" sx={{ fontSize: '1rem' }} />
                </Box>
              }
            />

            <Divider />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <ClearFiltersButton onClick={handleClear} disabled={!filtersChanged()} />

              <CustomButton size="small" onClick={handleSearch}>
                Apply
              </CustomButton>
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
};
