import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { HighlightedWord, highlightKeywords } from '../../../lib/highlight-keywords';
import { StringUtils } from '../../../utils/StringUtils';

interface IProps {
  abstract: string;
  keywords: string;
}

export const Abstract: React.FC<IProps> = ({ abstract = '', keywords }) => {
  const [parts, setParts] = useState<HighlightedWord[]>([]);

  useEffect(() => {
    if (!abstract || !keywords) return;
    setParts(highlightKeywords(abstract, keywords));
  }, []);
  return (
    <Typography variant="body2" sx={{ letterSpacing: 0.25 }} className="ph-no-capture">
      {parts.length ? (
        parts.map((part, index) => (
          <span
            key={index}
            style={{ fontWeight: part.bold ? 700 : 400 }}
            dangerouslySetInnerHTML={{ __html: StringUtils.removeNonGlyphCharacters(StringUtils.sanitize(part.text)) }}
          />
        ))
      ) : (
        <span
          dangerouslySetInnerHTML={{ __html: StringUtils.removeNonGlyphCharacters(StringUtils.sanitize(abstract)) }}
        />
      )}
    </Typography>
  );
};
