import { ClearAll } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';

import { CustomButton } from '../new/CustomButton';

export const ClearFiltersButton: React.FC<ButtonProps> = (props) => {
  return (
    <CustomButton
      size="small"
      color="secondary"
      variant="text"
      startIcon={<ClearAll />}
      aria-label="clear all filters"
      {...props}
    >
      Clear
    </CustomButton>
  );
};
