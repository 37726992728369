import { MenuBook } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';

import { Color } from '../../../lib/colors';
import { StringUtils } from '../../../utils/StringUtils';

interface JournalChipProps extends ChipProps {
  fitToLength?: number;
}

export const JournalChip: React.FC<JournalChipProps> = ({ variant = 'outlined', fitToLength, label, ...props }) => {
  return (
    <Chip
      variant={variant}
      size="small"
      label={
        StringUtils.isString(label) ? (
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            dangerouslySetInnerHTML={{
              __html: StringUtils.fitToLength(
                StringUtils.removeNonGlyphCharacters(StringUtils.sanitize(label as string)),
                fitToLength,
              ),
            }}
          />
        ) : (
          label
        )
      }
      icon={
        <MenuBook
          sx={{
            ...(variant === 'outlined' && { color: `${Color.Journal} !important` }),
            ...(variant === 'filled' && { color: `${Color.White} !important` }),
            fontSize: `${props.size === 'small' ? '14px' : '16px'} !important`,
          }}
        />
      }
      {...props}
      sx={{
        maxWidth: '100%',
        ...props.sx,
        ...(props.size === 'small' && { fontSize: 12, height: 'auto' }),
        ...(props.size === 'medium' && { fontSize: 16, height: 'auto' }),
        ...(variant === 'outlined' && { background: Color.White }),
        ...(variant === 'filled' && { background: `${Color.Journal} !important`, color: Color.White }),
      }}
    />
  );
};
