import { Box, Divider, Typography } from '@mui/material';

import { config } from '../../lib/config';
import { WebsiteNavigation } from '../../lib/navigation';
import { ExternalLink } from '../common/ExternalLink';

export const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ textAlign: 'center', p: 1, pb: 6 }}>
      <Divider />

      <Box sx={{ textAlign: 'center', mt: 6 }}>
        <Typography variant="caption">
          Copyright &copy; {new Date().getFullYear()} Keenious. All rights reserved.
        </Typography>
        <br />
        <Typography variant="caption">
          <ExternalLink href={`${config.WEBSITE_URL}${WebsiteNavigation.TermsOfUse}`} title="Terms of Use">
            Terms of Use
          </ExternalLink>
          <span style={{ padding: '0 4px' }}>&middot;</span>
          <ExternalLink href={`${config.WEBSITE_URL}${WebsiteNavigation.PrivacyPolicy}`} title="Privacy Policy">
            Privacy Policy
          </ExternalLink>
          <span style={{ padding: '0 4px' }}>&middot;</span>
          <ExternalLink href={`${config.WEBSITE_URL}${WebsiteNavigation.CookiePolicy}`} title="Cookie Policy">
            Cookie Policy
          </ExternalLink>
        </Typography>
      </Box>

      <Typography variant="caption">v{config.VERSION}</Typography>
    </Box>
  );
};
