import { ArrowBack, Close } from '@mui/icons-material';
import { Box, Container, Divider, Drawer, IconButton, Tooltip, Typography, drawerClasses } from '@mui/material';
import { useEffect, useRef } from 'react';

import { Scrollable } from '@keenious/libraries/components/addon/Scrollable';
import { useWindowSize } from '@keenious/libraries/hooks/useWindowSize';
import { Entity, useEntitiesStore } from '@keenious/libraries/store/useEntitiesStore';
import { sizes } from '@keenious/libraries/style/sizes';

import { Environment, environment } from '../../lib/environment';
import { TopBar } from '../common/TopBar/TopBar';

interface IProps {
  entity: Entity;
  children: React.ReactNode;
}

export const EntityWrapper: React.FC<IProps> = ({ entity, children }) => {
  const drawerRef = useRef(null);
  const windowSize = useWindowSize();

  const close = useEntitiesStore((state) => state.close);
  const closeAll = useEntitiesStore((state) => state.closeAll);

  useEffect(() => {
    if (!entity || !drawerRef.current) return null;
    // On load, focus on first available button on the entity for accessibility purposes
    drawerRef.current.querySelector('button').focus();
  }, [entity, drawerRef.current]);

  if (!entity) return null;

  return (
    <Drawer
      open={entity?.visible}
      variant="persistent"
      anchor="right"
      sx={{
        position: 'relative',
        width: environment === Environment.Web && windowSize.width > sizes.sidebarWidth ? sizes.sidebarWidth : '100%',
        [`.${drawerClasses.paper}`]: {
          width: environment === Environment.Web && windowSize.width > sizes.sidebarWidth ? sizes.sidebarWidth : '100%',
          maxWidth: '100%',
          sm: { borderLeft: 'none' },
          md: { borderLeft: '1px solid rgba(0, 0, 0, 0.12)' },
        },
        ...(!entity.focused && { display: 'none !important' }),
      }}
      style={{ zIndex: entity.zIndex }}
      ref={drawerRef}
    >
      <TopBar>
        <Tooltip title="Back">
          <IconButton color="inherit" sx={{ mr: 1 }} onClick={() => close(entity.id)} aria-label="close current drawer">
            <ArrowBack fontSize="small" />
          </IconButton>
        </Tooltip>

        <Typography variant="h5" sx={{ mt: 1 }}>
          {entity.title}
        </Typography>

        <Tooltip title="Close All">
          <IconButton
            color="inherit"
            sx={{ ml: 1, ...(environment === Environment.Word && { mr: 10 }) }}
            onClick={() => closeAll()}
            aria-label="close all drawers"
          >
            <Close fontSize="small" />
          </IconButton>
        </Tooltip>
      </TopBar>

      <Container
        sx={{ height: '100%', overflow: 'auto', padding: '0 !important', display: 'flex', flexDirection: 'column' }}
      >
        <Divider />

        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
          <Scrollable>{children}</Scrollable>
        </Box>
      </Container>
    </Drawer>
  );
};
