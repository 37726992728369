import { ArticleOutlined, Lightbulb } from '@mui/icons-material';
import { Tab, Tabs, Typography } from '@mui/material';

import { Color, Gradient } from '../../../lib/colors';
import { useUiStore } from '../../../store/useUiStore';
import { EntityTab } from '../../../types/entities';

export function EntityTabs() {
  const entityTab = useUiStore((state) => state.entityTab);
  const setEntityTab = useUiStore((state) => state.setEntityTab);

  const articlesColor = entityTab === EntityTab.Articles ? Color.Publication : Color.SlateGrey;
  const topicsColor = entityTab === EntityTab.Topics ? Color.Topic : Color.SlateGrey;
  const indicatorColor = entityTab === EntityTab.Articles ? Gradient.GreenToBlue : Gradient.OrangeToViolet;

  return (
    <Tabs
      value={entityTab}
      onChange={(event, newValue) => setEntityTab(newValue)}
      sx={{
        minHeight: 0,
        '& .MuiTabs-indicator': {
          transition: 'none',
          background: indicatorColor,
        },
      }}
      variant="fullWidth"
      aria-label="Search context"
      role="tabpanel"
      indicatorColor="secondary"
      selectionFollowsFocus
    >
      <Tab
        id="context-tab-articles"
        aria-controls="context-tabpanel-articles"
        label={
          <Typography
            variant="caption"
            sx={{ display: 'flex', alignItems: 'center', color: articlesColor, lineHeight: 1 }}
          >
            <ArticleOutlined htmlColor={articlesColor} sx={{ fontSize: '1rem', mr: 2 }} />
            <span>Articles</span>
          </Typography>
        }
        sx={{ textTransform: 'none', minHeight: 0, p: 4 }}
      />

      <Tab
        id="context-tab-topics"
        aria-controls="context-tabpanel-topics"
        label={
          <Typography
            variant="caption"
            sx={{ display: 'flex', alignItems: 'center', color: topicsColor, lineHeight: 1 }}
          >
            <Lightbulb htmlColor={topicsColor} sx={{ fontSize: '1rem', mr: 2 }} />
            <span>Topics</span>
          </Typography>
        }
        sx={{ textTransform: 'none', minHeight: 0, p: 4 }}
      />
    </Tabs>
  );
}
