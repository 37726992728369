import { Box, Typography } from '@mui/material';

import { TopicEntity } from '../../lib/excover';
import { TopicCard } from '../topics/Topic/TopicCard';

interface IProps {
  topics: TopicEntity[];
  loading: boolean;
  AppliedFiltersComponent?: React.ReactNode;
  children: React.ReactNode;
}

export const TopicsTabPanel: React.FC<IProps> = ({ topics, loading, AppliedFiltersComponent, children }) => {
  return (
    <>
      <Box sx={{ mx: 4 }}>{AppliedFiltersComponent}</Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto', padding: (theme) => theme.spacing(2) }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto', mx: -2 }}>
          {loading ? (
            <>
              {[...Array(10)].map((item, index) => (
                <TopicCard key={index} loading />
              ))}
            </>
          ) : topics.length > 0 ? (
            children
          ) : (
            <Box sx={{ mx: 6 }}>
              <Typography variant="body2">
                Sorry, we could not find any topics matching your filtering requirements.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
