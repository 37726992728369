export enum Color {
  Purple = '#6030FF',
  Text = '#1A1A1A',
  White = '#FFFFFF',
  Grey = '#F6F7F8',
  Grey2 = '#F2F3F3',
  Grey3 = '#BDBDBD',
  Grey4 = '#767676',

  SlateGrey = '#58747F',
  Black = '#000000',

  Publication = '#0D30AB',
  Author = '#A00F0F',
  Journal = '#23A00F',
  Institution = '#C65A1D',
  Topic = '#A485FF',
  DarkGrey = '#455256',

  Yellow = '#f2ac39',
  Red = '#F05030',
  Blue = '#00afe3',
  Green = '#2a920d',

  Link = '#2D6ABC',
  VisitedLink = '#660099',
}

export enum Gradient {
  Drawer = 'linear-gradient(180deg, #FDFDFF 0%, #F8F8FF 100%)',
  GreenToBlue = 'linear-gradient(270deg, #73E2FF 2.43%, #92FFC6 41.46%, #E2FFC2 100%)',
  OrangeToViolet = 'linear-gradient(90deg, #ffc581 2.43%, #fea3c6 41.46%, #c78dfe 100%)',
  TransparentToWhite = `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, #FFFFFF 100%)`,
  Plus = 'linear-gradient(261deg, #FFF -41.32%, #FFBD6C -7.43%, #FF8EC1 63.87%, #BB78FF 109.15%)',
}
