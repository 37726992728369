import { useMemo } from 'react';

import { IndividualSubscription } from '../../lib/factories/IndividualSubscription';
import { TeamSubscription } from '../../lib/factories/TeamSubscription';
import { User } from '../../lib/factories/User';
import { InstitutionEntity } from '../../lib/types';
import { useGetUser } from '../data/auth/useGetUser';
import { useGetInstitutionByIp } from '../data/institutions/useGetInstitutionByIp';

interface Props {
  poll?: boolean;
}

export function useAuth({ poll }: Props = {}) {
  const { data: userData } = useGetUser(
    poll ? { refetchOnWindowFocus: true, refetchInterval: 1000 * 10, staleTime: 1000 * 10 } : undefined,
  );
  const { data: institutionByIp } = useGetInstitutionByIp();

  const auth = useMemo(() => {
    let user: User | null = null;
    let institution: InstitutionEntity | null = institutionByIp || null;
    let subscription: IndividualSubscription | TeamSubscription | null = null;
    let linkResolverBaseUrl = institution?.settings?.linkResolverBaseUrl || '';
    let isPlusUser = Boolean(institutionByIp);

    if (userData) {
      user = new User(userData);
      institution = user.temporaryInstitution || user.institution || null;

      if (user.subscription) {
        subscription = new IndividualSubscription(user.subscription);
      } else if (user?.team?.subscription) {
        subscription = new TeamSubscription(user.team.subscription, user.id);
      }

      // If a Keenious Admin user is temporarily assigned to an institution, use the institution's link resolver base URL
      if (user.temporaryInstitution) {
        linkResolverBaseUrl = user.temporaryInstitution?.settings?.linkResolverBaseUrl || '';
      } else if (institution) {
        // If an institution is found, use the institution's link resolver base URL
        linkResolverBaseUrl = institution?.settings?.linkResolverBaseUrl || '';
      } else if (user.settings?.linkResolverBaseUrl) {
        // Otherwise, use the user's link resolver base URL
        linkResolverBaseUrl = user.settings?.linkResolverBaseUrl || '';
      }

      isPlusUser = Boolean(subscription?.hasPlusAccess() || institution || user.isKeeniousAdmin());
    }

    return { user, institution, subscription, linkResolverBaseUrl, isPlusUser };
  }, [userData, institutionByIp]);

  return auth;
}
