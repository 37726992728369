import { ThemeOptions, darken, lighten } from '@mui/material';

import { Color } from './colors';

export const theme: ThemeOptions = {
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,
    h1: { fontSize: 82, fontWeight: 600, lineHeight: 0.95 },
    h2: { fontSize: 70, fontWeight: 600, lineHeight: 0.94 },
    h3: { fontSize: 40, fontWeight: 600, lineHeight: 1.1 },
    h4: { fontSize: 24, fontWeight: 600, lineHeight: 1.16 },
    h5: { fontSize: 14, fontWeight: 600, lineHeight: 1.28 },
    body1: { fontSize: 16, fontWeight: 400, lineHeight: 1.5 },
    body2: { fontSize: 14, fontWeight: 400, lineHeight: 1.28 },
    button: { fontSize: 16, fontWeight: 400, lineHeight: 1.125 },
    caption: { fontSize: 14, fontWeight: 400, lineHeight: 1.28, color: Color.Grey4 },
  },
  palette: {
    mode: 'light',
    primary: { main: Color.Purple },
    secondary: { main: Color.DarkGrey },
    warning: { main: Color.Yellow },
    error: { main: Color.Red },
    info: { main: Color.Blue },
    success: { main: Color.Green },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overflow: 'hidden',
        },
        body: {
          overflow: 'hidden',
          backgroundColor: Color.White,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        standardWarning: {
          backgroundColor: lighten(Color.Yellow, 0.6),
          color: darken(Color.Yellow, 0.6),
          '& .MuiAlert-icon': {
            color: darken(Color.Yellow, 0.4),
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '20px !important',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
        },
      },
      variants: [
        {
          props: { variant: 'elevation' },
          style: {
            boxShadow: '1px 4px 10px rgba(0, 0, 0, 0.25)',
          },
        },
      ],
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 500,
        disableInteractive: true,
      },
      styleOverrides: {
        tooltip: {
          background: Color.DarkGrey,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          border: '1px solid transparent',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            pointerEvents: 'auto !important',
          },
        },
      },
    },
  },
};
