import { Box, Divider } from '@mui/material';

import { Scrollable } from '@keenious/libraries/components/addon/Scrollable';
import { EntityTabPanels } from '@keenious/libraries/components/addon/entities/EntityTabPanels';
import { EntityTabs } from '@keenious/libraries/components/addon/entities/EntityTabs';
import { AlertMessages } from '@keenious/libraries/components/alertMessages/AlertMessages/AlertMessages';
import { Color } from '@keenious/libraries/lib/colors';
import { useUiStore } from '@keenious/libraries/store/useUiStore';

import { SearchResultsContainer } from '../../../containers/search/SearchResultsContainer';
import { TopicsTabPanelContainer } from '../../../containers/topics/TopicsTabPanelContainer';
import { useSearchStore } from '../../../store/useSearchStore';
import { SearchBar } from '../../search/SearchBar/SearchBar';

export function ResultPage() {
  const loading = useUiStore((state) => state.loading);
  const resetAndApplySearch = useSearchStore((state) => state.resetAndApplySearch);

  return (
    <>
      <Box sx={{ backgroundColor: Color.White }}>
        <SearchBar />
        <EntityTabs />
        <Divider />
      </Box>

      <Scrollable
        onMount={() => {
          if (loading) return;
          resetAndApplySearch();
        }}
      >
        <AlertMessages />

        <EntityTabPanels
          SearchResultsComponent={<SearchResultsContainer />}
          TopicsTabPanelComponent={<TopicsTabPanelContainer />}
        />
      </Scrollable>
    </>
  );
}
