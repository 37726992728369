import { Box, Fade, TooltipProps } from '@mui/material';

import { TopicWithTypeEntity } from '../../../lib/excover';
import { TopicChip, TopicChipProps } from '../TopicChip/TopicChip';
import { PreviewTooltip } from './PreviewTooltip';

const ENTER_DELAY = 900;
const LEAVE_DELAY = 100;

export interface ITopicProps {
  topic?: TopicWithTypeEntity;
  TopicChipProps?: TopicChipProps;
  TooltipProps?: Partial<TooltipProps>;
  children?: React.ReactNode;
}

export const Topic: React.FC<ITopicProps> = ({ topic, TopicChipProps, TooltipProps, children }) => {
  const chip = (
    <Box sx={{ display: 'inline-block' }}>
      <TopicChip label={topic?.display_name} {...TopicChipProps} />
    </Box>
  );

  return (
    <PreviewTooltip
      arrow
      followCursor={false}
      TransitionComponent={Fade}
      disableInteractive={false}
      placement="bottom"
      enterDelay={ENTER_DELAY}
      enterNextDelay={ENTER_DELAY}
      leaveDelay={LEAVE_DELAY}
      {...TooltipProps}
      title={children}
    >
      {chip}
    </PreviewTooltip>
  );
};
