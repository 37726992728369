import { Close, FileCopy, GetApp } from '@mui/icons-material';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';

import { useGetCitations } from '../../../hooks/data/citations/useGetCitations';
import { AnalyticsUtils } from '../../../lib/analytics';
import { config } from '../../../lib/config';
import { WorkEntity } from '../../../lib/excover';
import { CitationStyleEnum } from '../../../lib/types';
import { useCitationsStore } from '../../../store/useCitationsStore';
import { CitationUtils } from '../../../utils/CitationUtils';
import { WorkUtils } from '../../../utils/WorkUtils';
import { ExternalLink } from '../../common/ExternalLink';
import { CustomButton } from '../../new/CustomButton';
import { CustomDialog } from '../../new/CustomDialog';
import { FilledTextField } from '../../new/FilledTextField';
import { PublicationChip } from '../../publication/PublicationChip/PublicationChip';

interface IProps {
  works: WorkEntity[];
  open: boolean;
  onClose: () => void;
}

export const CitationDialog: React.FC<IProps> = ({ works, open, onClose }) => {
  const selectedStyle = useCitationsStore((state) => state.selectedStyle);
  const setSelectedStyle = useCitationsStore((state) => state.setSelectedStyle);

  const workIds = works.map((work) => WorkUtils.getId(work.id));
  const { data: citations, isFetching } = useGetCitations(workIds, selectedStyle.value);

  const ref = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);

  function handleChangeSelection(value: string) {
    const citationStyle = CitationUtils.CITATION_STYLES.find((citationStyle) => citationStyle.value === value);
    if (!citationStyle) return;
    setSelectedStyle(citationStyle);
    AnalyticsUtils.captureEvent('Citations: Changed Citation Style', { style: citationStyle.label });
  }

  /**
   * Copy content to clipboard
   */
  function handleCopy() {
    if (!ref.current) return;

    const range = document.createRange();
    range.selectNode(ref.current);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);
    document.execCommand('copy');
    window.getSelection()?.removeAllRanges();

    toast.success('Copied to clipboard!');
    AnalyticsUtils.captureEvent('Citations: Copied Citations To Clipboard', {
      style: selectedStyle.label,
      works_length: works.length,
    });
  }

  /**
   * Download citation to file
   */
  function handleDownload() {
    AnalyticsUtils.captureEvent('Citations: Downloaded Citations', {
      style: selectedStyle.label,
      works_length: works.length,
    });
  }

  return (
    <CustomDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container spacing={6}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="h4">{workIds.length > 1 ? 'Export results' : 'Cite Publication'}</Typography>
              {workIds.length > 1 && (
                <Typography variant="subtitle1">Export the publications in your current page result list</Typography>
              )}
            </Box>

            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <FilledTextField
              size="small"
              select
              value={selectedStyle.value}
              SelectProps={{
                open: isSelectorOpen,
                onOpen: () => setIsSelectorOpen(true),
                onClose: () => setIsSelectorOpen(false),
                onChange: () => {},
              }}
              label="Style"
            >
              {CitationUtils.CITATION_STYLES.map((style) => (
                <MenuItem
                  key={style.value}
                  value={style.value}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleChangeSelection(style.value);
                    setIsSelectorOpen(false);
                  }}
                >
                  {style.label}
                </MenuItem>
              ))}
            </FilledTextField>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>
          To{' '}
          <ExternalLink
            href="https://usingsources.fas.harvard.edu/what-constitutes-plagiarism-0"
            title="What Constitutes Plagiarism?"
          >
            avoid plagiarism
          </ExternalLink>{' '}
          remember to read and{' '}
          <ExternalLink href="https://usingsources.fas.harvard.edu/why-use-sources-0" title="Why Use Sources?">
            credit sources properly
          </ExternalLink>{' '}
          🔬. Here is some inspiration on{' '}
          <ExternalLink
            href="https://help.keenious.com/en/articles/211334-documenting-the-use-of-keenious-in-your-work"
            title="Documenting the Use of Keenious in Your Work"
          >
            How to Document Your Use of Keenious in Your Work
          </ExternalLink>
          .
        </Typography>

        <br />

        <Tooltip title="Click to copy" placement="top">
          <Box
            ref={ref}
            onClick={handleCopy}
            sx={{
              padding: 8,
              border: `1px dashed ${theme.palette.grey[400]}`,
              borderRadius: 4,
              cursor: 'pointer',
              overflow: 'auto',
            }}
          >
            {!citations ? (
              <Skeleton height={workIds.length > 1 ? 200 : 95} sx={{ transform: 'scale(1)' }} />
            ) : (
              <Box
                component="div"
                dangerouslySetInnerHTML={{ __html: citations }}
                className="ph-no-capture"
                sx={{
                  maxHeight: 200,
                  ...(selectedStyle.value === CitationStyleEnum.RIS && {
                    whiteSpace: 'pre-wrap',
                  }),
                }}
              />
            )}
          </Box>
        </Tooltip>

        {workIds.length > 1 && (
          <>
            <Typography variant="body2" sx={{ lineHeight: 1.5, mt: 10 }}>
              <strong>Exporting the following {workIds.length} publications:</strong>{' '}
            </Typography>

            {works.map((work) => (
              <PublicationChip key={work.id} label={work.title} fitToLength={30} sx={{ m: 1 }} />
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <CustomButton
              size="small"
              onClick={handleCopy}
              color="primary"
              aria-label="copy citation to clipboard"
              startIcon={<FileCopy />}
              disabled={isFetching}
            >
              Copy to Clipboard
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              href={`${config.API_URL}/api/citations/download?${workIds
                .map((workId) => `workIds[]=${workId}`)
                .join('&')}&style=${selectedStyle.value}`}
              target="_blank"
              size="small"
              onClick={handleDownload}
              color="primary"
              aria-label="download citation as a file"
              startIcon={<GetApp />}
              disabled={isFetching}
            >
              Download
            </CustomButton>
          </Grid>
        </Grid>
      </DialogActions>
    </CustomDialog>
  );
};
