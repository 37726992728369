import { Box, Grid, Tooltip, Typography } from '@mui/material';

import PlusSymbol from '../../assets/plus-symbol.svg';
import { useAuth } from '../../hooks/features/useAuth';
import { AnalyticsUtils } from '../../lib/analytics';
import { useFiltersStore } from '../../store/useFiltersStore';
import { FilledTextField } from '../new/FilledTextField';

interface Props {
  onEnter: () => void;
}

export function CitationCountFilter({ onEnter }: Props) {
  const { user, isPlusUser } = useAuth();

  const filters = useFiltersStore((state) => state.filters);
  const updateFilters = useFiltersStore((state) => state.updateFilters);

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      onEnter();
    }
  }

  return (
    <Tooltip
      title={
        !isPlusUser
          ? user?.isTeamUser()
            ? 'Please contact a team admin to give you Plus Access to access this feature'
            : 'Upgrade to Plus to access this feature'
          : undefined
      }
    >
      <Box sx={{ p: 4 }}>
        <Typography variant="body2" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <strong>Citation Count</strong>
          {!isPlusUser && <PlusSymbol height={14} />}
        </Typography>

        <Grid container spacing={6} sx={{ opacity: isPlusUser ? 1 : 0.5 }}>
          <Grid item xs={6}>
            <FilledTextField
              label="Minimum"
              type="tel"
              size="small"
              placeholder="No limit"
              value={filters.cited_by_count_min}
              onChange={(event) => {
                const min = Number(event.target.value);
                const max = filters.cited_by_count_max;
                updateFilters({ cited_by_count_min: min });
                AnalyticsUtils.captureEvent('Filters: Changed "Citation Count" Filter', { min, max });
              }}
              onKeyDown={handleKeyDown}
              inputProps={{ min: 0, max: filters.cited_by_count_max }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={!isPlusUser}
            />
          </Grid>

          <Grid item xs={6}>
            <FilledTextField
              label="Maximum"
              type="tel"
              size="small"
              placeholder="No limit"
              value={filters.cited_by_count_max}
              onChange={(event) => {
                const min = filters.cited_by_count_min;
                const max = Number(event.target.value);
                updateFilters({ cited_by_count_max: max });
                AnalyticsUtils.captureEvent('Filters: Changed "Citation Count" Filter', { min, max });
              }}
              onKeyDown={handleKeyDown}
              inputProps={{ min: filters.cited_by_count_min, max: 1e7 }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={!isPlusUser}
            />
          </Grid>
        </Grid>
      </Box>
    </Tooltip>
  );
}
