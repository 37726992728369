import { CssBaseline, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createContext, useEffect, useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import {
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { Loader } from '@keenious/libraries/components/common/Loader/Loader';
import { AnalyticsProvider } from '@keenious/libraries/lib/analytics';
import { config } from '@keenious/libraries/lib/config';
import { EnvironmentUtils } from '@keenious/libraries/utils/EnvironmentUtils';
import { SentryUtils } from '@keenious/libraries/utils/SentryUtils';

import { Environment, environment } from '../../../lib/environment';
import { theme } from '../../../style/theme';
import { OfficeUtils } from '../../../utils/OfficeUtils';
import { Layout } from '../Layout/Layout';
import { HomePage } from '../Routes/HomePage';
import { ResultPage } from '../Routes/ResultPage';
import { ErrorFallback } from './ErrorFallback';
import { SentryErrorFallback } from './SentryErrorFallback';

const DisplayContext = createContext<'sidebar' | 'screen' | undefined>(undefined);

Sentry.init({
  enabled: !EnvironmentUtils.isLocal(),
  dsn: config.SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Ignore errors that match the following patterns or messages
  ignoreErrors: SentryUtils.ignoredPhrases,
});

const createRouter = environment !== Environment.Web ? createHashRouter : createBrowserRouter;
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorFallback />,
    element: (
      <Layout>
        <HomePage />
      </Layout>
    ),
  },
  {
    path: '/result',
    errorElement: <ErrorFallback />,
    element: (
      <Layout>
        <ResultPage />
      </Layout>
    ),
  },
]);

export const App: React.FC = () => {
  const [initialized, setInitialized] = useState(false);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: Infinity,
            refetchOnWindowFocus: true,
            retry: false,
          },
        },
      }),
  );

  useEffect(() => {
    if (environment === Environment.Word) {
      OfficeUtils.initialize();
    }

    setInitialized(true);
  }, []);

  useEffect(() => {
    let toastId = null;
    let offlineTimeout = null;

    function handleOffline() {
      // Start a 3-second timeout before showing the toast
      offlineTimeout = setTimeout(() => {
        toastId = toast.error('No internet connection', {
          duration: Infinity,
        });
      }, 3000);
    }

    // Function to handle coming back online
    function handleOnline() {
      // Clear the timeout if coming back online within 3 seconds
      if (offlineTimeout) {
        clearTimeout(offlineTimeout);
      }

      // Dismiss the toast when coming back online
      if (toastId) {
        toast.dismiss(toastId);
      }
    }

    // Add event listeners
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    // Cleanup event listeners and timeout on unmount
    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
      if (offlineTimeout) {
        clearTimeout(offlineTimeout);
      }
    };
  }, []);

  return (
    <AnalyticsProvider environment={environment}>
      <QueryClientProvider client={queryClient}>
        <DisplayContext.Provider value="sidebar">
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <Sentry.ErrorBoundary fallback={SentryErrorFallback}>
              {initialized ? <RouterProvider router={router} /> : <Loader />}

              <Toaster position="bottom-center" reverseOrder={false} />
            </Sentry.ErrorBoundary>
          </ThemeProvider>
        </DisplayContext.Provider>
      </QueryClientProvider>
    </AnalyticsProvider>
  );
};
