import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { v4 as uuid } from 'uuid';

import { useCheckAuthRequest } from '@keenious/libraries/hooks/data/auth/requests/useCheckAuthRequest';
import { useCreateAuthRequest } from '@keenious/libraries/hooks/data/auth/requests/useCreateAuthRequest';
import { useSignOut } from '@keenious/libraries/hooks/data/auth/useSignOut';
import { useAuth } from '@keenious/libraries/hooks/features/useAuth';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { config } from '@keenious/libraries/lib/config';
import { WebsiteNavigation } from '@keenious/libraries/lib/navigation';
import { useUiStore } from '@keenious/libraries/store/useUiStore';

import { useSearchStore } from '../store/useSearchStore';

const SIGN_IN_APPROVE_PREFIX = `${WebsiteNavigation.SignInApprove}?sessionId=`;
const SIGN_UP_PREFIX = `${WebsiteNavigation.SignUp}?sessionId=`;
const SIGN_IN_TIMEOUT = 5 * 60000; // 5 minutes
const SIGN_IN_RETRY = 3000;

export function useAddonSignIn() {
  const { user } = useAuth();
  const checkAuthRequest = useCheckAuthRequest();
  const createAuthRequest = useCreateAuthRequest();
  const signOut = useSignOut();

  const scrollToTop = useUiStore((state) => state.scrollToTop);
  const applySearch = useSearchStore((state) => state.applySearch);

  const [sessionId, setSessionId] = useState('');
  const [signInClicked, setSignInClicked] = useState(false);
  const [signInUrl, setSignInUrl] = useState(`${SIGN_IN_APPROVE_PREFIX}${sessionId}`);
  const [signUpClicked, setSignUpClicked] = useState(false);
  const [signUpUrl, setSignUpUrl] = useState(`${SIGN_UP_PREFIX}${sessionId}`);

  /**
   * Sign in user
   */
  useEffect(() => {
    if (!user || !(signInClicked || signUpClicked)) return;
    toast.success('Successfully signed in');
    requestAnimationFrame(() => {
      applySearch();
      setSignInClicked(false);
      setSignUpClicked(false);
      AnalyticsUtils.captureEvent('Auth: Signed In');
      scrollToTop();
    });
  }, [user]);

  /**
   * Set unique session id after mount
   * Requires `requestAnimationFrame` to avoid generating a new id on every test run
   */
  useEffect(() => {
    regenerateSessionId();
  }, []);

  // Check the auth request every 3 seconds for a maximum of 5 minutes after sign in
  useEffect(() => {
    if (!sessionId || !(signInClicked || signUpClicked)) return;

    let elapsedTime = 0;

    const interval = setInterval(async () => {
      elapsedTime += SIGN_IN_RETRY;
      checkAuthRequest.mutate(sessionId);

      if (elapsedTime > SIGN_IN_TIMEOUT) {
        clearInterval(interval);
      }
    }, SIGN_IN_RETRY);

    return () => {
      clearInterval(interval);
    };
  }, [sessionId, signInClicked, signUpClicked]);

  /**
   * Requires `requestAnimationFrame` to avoid breaking tests on build due to the WEBSITE_URL environment variable being different
   */
  useEffect(() => {
    requestAnimationFrame(() => {
      setSignInUrl(`${config.WEBSITE_URL}${SIGN_IN_APPROVE_PREFIX}${sessionId}`);
      setSignUpUrl(`${config.WEBSITE_URL}${SIGN_UP_PREFIX}${sessionId}`);
    });
  }, [sessionId]);

  /**
   * Regenerate session id on the next tick
   */
  function regenerateSessionId() {
    requestAnimationFrame(() => {
      setSessionId(uuid());
    });
  }

  /**
   * Authenticate user and regenerate session id
   */
  function onSignIn() {
    createAuthRequest.mutate(sessionId);
    AnalyticsUtils.captureEvent('Auth: Clicked "Sign In" Button');
    setSignInClicked(true);
  }

  /**
   * Authenticate user and regenerate session id
   */
  function onSignUp() {
    createAuthRequest.mutate(sessionId);
    AnalyticsUtils.captureEvent('Auth: Clicked "Sign Up" Button');
    setSignUpClicked(true);
  }

  /**
   * Sign out user
   */
  async function onSignOut() {
    await signOut.mutateAsync();
    regenerateSessionId();
    AnalyticsUtils.captureEvent('Auth: Signed Out');
    AnalyticsUtils.resetUser();
    window.location.reload();
  }

  return { sessionId, onSignIn, onSignUp, onSignOut, signInUrl, signUpUrl };
}
