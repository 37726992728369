import { ArrowBack, Clear, Info, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  Link,
  Tooltip,
  darken,
  filledInputClasses,
  inputAdornmentClasses,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import KeeniousLogo from '@keenious/libraries/assets/keenious-logo.svg';
import KeeniousPlusLogo from '@keenious/libraries/assets/keenious-plus-logo.svg';
import { FilledTextField } from '@keenious/libraries/components/new/FilledTextField';
import { useAuth } from '@keenious/libraries/hooks/features/useAuth';
import { useEffectAsync } from '@keenious/libraries/hooks/useEffectAsync';
import { AnalyticsUtils } from '@keenious/libraries/lib/analytics';
import { Color } from '@keenious/libraries/lib/colors';
import { config } from '@keenious/libraries/lib/config';
import { AddonNavigation, WebsiteNavigation } from '@keenious/libraries/lib/navigation';
import { useFiltersStore } from '@keenious/libraries/store/useFiltersStore';
import { useTopicsStore } from '@keenious/libraries/store/useTopicsStore';
import { useUiStore } from '@keenious/libraries/store/useUiStore';
import { EntityTab } from '@keenious/libraries/types/entities';

import { HighlightedTextTidbitContainer } from '../../../containers/filters/HighlightedTextTidbitContainer';
import { useAddonSignIn } from '../../../hooks/useAddonSignIn';
import { useEditorStore } from '../../../store/useEditorStore';
import { useSearchStore } from '../../../store/useSearchStore';
import { TopBar } from '../../common/TopBar/TopBar';

export const SearchBar: React.FC = () => {
  const { user, isPlusUser } = useAuth();

  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const { onSignIn, signInUrl } = useAddonSignIn();
  const navigate = useNavigate();
  const mounted = useRef(false);
  const ref = useRef<HTMLInputElement>(null);

  const entityTab = useUiStore((state) => state.entityTab);
  const loading = useUiStore((state) => state.loading);
  const highlightedText = useEditorStore((state) => state.getHighlightedText().trim());
  const highlighting = Boolean(highlightedText);
  const topicsRequest = useTopicsStore((state) => state.topicsRequest);
  const topicsQueryString = useTopicsStore((state) => state.queryString);
  const articlesQueryString = useFiltersStore((state) => state.queryString);
  const filteredHighlightedText = useFiltersStore((state) => state.highlightedText);
  const setHighlightedText = useFiltersStore((state) => state.setHighlightedText);
  const setArticlesQueryString = useFiltersStore((state) => state.setQueryString);
  const setTopicsQueryString = useTopicsStore((state) => state.setQueryString);
  const resetAndApplySearch = useSearchStore((state) => state.resetAndApplySearch);
  const applySearch = useSearchStore((state) => state.applySearch);
  const searchRequest = useSearchStore((state) => state.searchRequest);
  const searchTopics = useSearchStore((state) => state.searchTopics);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      queryString: entityTab === EntityTab.Articles ? articlesQueryString : topicsQueryString,
      reset: true,
    },
    onSubmit: async (values, formikHelpers) => {
      if (highlightedText && highlightedText !== filteredHighlightedText) {
        setHighlightedText(highlightedText);
      }

      // Remove focus from the search bar
      if (ref.current) {
        ref.current.blur();
      }

      if (entityTab === EntityTab.Articles) {
        const keywordsChanged = values.queryString !== articlesQueryString;
        if (keywordsChanged) {
          values.reset = true;
        }
        setArticlesQueryString(values.queryString);
        if (values.reset) {
          await resetAndApplySearch();
        } else {
          await applySearch();
        }
      }

      if (entityTab === EntityTab.Topics) {
        const keywordsChanged = values.queryString !== topicsQueryString;
        if (keywordsChanged) {
          values.reset = true;
        }
        setTopicsQueryString(values.queryString);
        await searchTopics();
      }

      formikHelpers.setFieldValue('reset', false);
    },
  });

  // Reset the query string value to the one in the store when the user runs a new search
  useEffect(() => {
    if (entityTab === EntityTab.Articles) {
      formik.setFieldValue('queryString', articlesQueryString);
    }
  }, [searchRequest]);

  // Reset the query string value to the one in the store when the user runs a new search
  useEffect(() => {
    if (entityTab === EntityTab.Topics) {
      formik.setFieldValue('queryString', topicsQueryString);
    }
  }, [topicsRequest]);

  // When the user switches between entity tabs, re-run the search if the query string has changed since the last search
  useEffectAsync(async () => {
    if (!mounted.current) return;

    if (highlightedText && highlightedText !== filteredHighlightedText) {
      setHighlightedText(highlightedText);
    }

    if (entityTab === EntityTab.Articles) {
      const queryChanged = articlesQueryString !== searchRequest?.query_string;
      if (queryChanged) {
        await resetAndApplySearch();
      }
    }

    if (entityTab === EntityTab.Topics) {
      const queryChanged = topicsQueryString
        ? topicsQueryString && topicsQueryString !== topicsRequest?.query_string
        : searchRequest?.query_texts[0].text && searchRequest?.query_texts[0].text !== topicsRequest?.query_string;
      if (queryChanged || !topicsRequest) {
        await searchTopics();
      }
    }
  }, [entityTab]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <TopBar sx={{ backgroundColor: Color.White, flexDirection: 'column', pb: 4 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            my: 4,
            position: 'relative',
          }}
        >
          <Tooltip title="Back">
            <IconButton
              color="inherit"
              onClick={() => {
                navigate(AddonNavigation.Home);
              }}
              aria-label="back to addon homepage"
            >
              <ArrowBack fontSize="small" />
            </IconButton>
          </Tooltip>

          <Link
            color="inherit"
            href={config.WEBSITE_URL}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
          >
            {isPlusUser ? <KeeniousPlusLogo height={22} /> : <KeeniousLogo height={22} />}
          </Link>

          {!user && (
            <Button
              size="small"
              variant="text"
              color="inherit"
              href={signInUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onSignIn}
            >
              Sign In
            </Button>
          )}

          {user && !isPlusUser && (
            <Button
              size="small"
              variant="text"
              color="inherit"
              href={`${config.WEBSITE_URL}${WebsiteNavigation.Pricing}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                AnalyticsUtils.captureEvent('Landing: Clicked "Upgrade" Call To Action', {
                  location: 'addon-top-bar',
                })
              }
            >
              Upgrade
            </Button>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            borderRadius: '8px',
            transition: 'all 0.1s ease-in-out',
            boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.06)',
            ...(focused && {
              boxShadow: '0px 2px 6px 0px rgba(38, 0, 161, 0.18)',
            }),
            '&:hover': {
              boxShadow: '0px 2px 6px 0px rgba(38, 0, 161, 0.18)',
            },
          }}
        >
          <FilledTextField
            inputRef={ref}
            name="queryString"
            fullWidth
            size="small"
            placeholder={`Search ${entityTab === EntityTab.Articles ? 'articles' : 'topics'}`}
            autoComplete="off"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            InputProps={{
              endAdornment: (
                <>
                  {formik.values.queryString && hovered && (
                    <InputAdornment position="end" sx={{ m: 0 }}>
                      <Tooltip title="Clear">
                        <IconButton
                          onClick={() => {
                            formik.setFieldValue('queryString', '');
                            formik.submitForm();
                          }}
                        >
                          <Clear fontSize="small" color="action" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )}

                  {!formik.values.queryString && (
                    <>
                      {entityTab === EntityTab.Articles && (
                        <InputAdornment position="end" sx={{ mr: 2 }}>
                          <Tooltip title='Search for articles relevant to your document. Supports AND(+), OR, NOT(-), phrase matching (") and grouping ().'>
                            <Info fontSize="small" color="disabled" />
                          </Tooltip>
                        </InputAdornment>
                      )}

                      {entityTab === EntityTab.Topics && (
                        <InputAdornment position="end" sx={{ mr: 2 }}>
                          <Tooltip title="Search in tens of thousands of topics by name or description.">
                            <Info fontSize="small" color="disabled" />
                          </Tooltip>
                        </InputAdornment>
                      )}
                    </>
                  )}
                </>
              ),
            }}
            sx={{
              [`& .${inputAdornmentClasses.root}`]: {
                mt: '0 !important',
              },
              [`& .${filledInputClasses.input}`]: {
                py: 4,
              },
              ['& .MuiFilledInput-root']: {
                pl: 0,
                pr: 2,
                borderRadius: highlighting ? '8px 0 0 0 !important' : ' 8px 0 0 8px !important',
                background: 'white !important',
                border: '1px solid rgba(0, 0, 0, 0.15)',
                borderRight: 'none',
                transition: 'border-color 0.1s ease-in-out',
                ...(!loading && {
                  '&.Mui-focused, &:hover': {
                    borderColor: highlighting ? Color.DarkGrey : Color.Purple,
                  },
                }),
                height: 39,
              },
            }}
            onKeyDown={(event) => {
              if (event.key === 'Escape' && event.target instanceof HTMLInputElement) {
                event.target.blur();
              }
            }}
            value={formik.values.queryString}
            onChange={formik.handleChange}
          />

          <IconButton
            disabled={loading}
            type="submit"
            color="inherit"
            aria-label="refresh search results"
            sx={{
              height: 39,
              px: 5,
              py: 4,
              overflow: 'hidden',
              borderRadius: highlighting ? '0 8px 0 0 !important' : '0 8px 8px 0 !important',
              background: `${Color.Purple} !important`,
              color: `${Color.White} !important`,
              '&:hover': {
                background: `${darken(Color.Purple, 0.2)} !important`,
              },
              ...(highlighting && {
                borderColor: Color.DarkGrey,
                background: `${Color.DarkGrey} !important`,
                '&:hover': {
                  background: `${darken(Color.DarkGrey, 0.2)} !important`,
                },
              }),
              ...(loading && {
                borderColor: (theme) => `${theme.palette.action.disabledBackground} !important`,
                background: (theme) => `${theme.palette.action.disabledBackground} !important`,
                color: (theme) => `${theme.palette.action.disabled} !important`,
              }),
            }}
          >
            {loading ? (
              <CircularProgress size={22.88} sx={{ p: 1 }} color="inherit" disableShrink />
            ) : (
              <Search fontSize="small" />
            )}
          </IconButton>
        </Box>

        <Collapse in={highlighting}>
          <HighlightedTextTidbitContainer />
        </Collapse>
      </TopBar>
    </Box>
  );
};
