import { Box, Button } from '@mui/material';

import { ExternalNavigation } from '../../lib/navigation';

export const HelpButton: React.FC = () => {
  return (
    <Box sx={{ position: 'fixed', bottom: 0, right: 0, zIndex: 99999 }}>
      <Button
        variant="contained"
        size="small"
        sx={{
          borderRadius: '20px 0 0 0 !important',
          p: '10px 12px 6px 16px !important',
        }}
        fullWidth
        href={ExternalNavigation.HelpCenter}
        target="_blank"
        rel="noopener noreferrer"
      >
        Help
      </Button>
    </Box>
  );
};
