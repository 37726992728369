import KeeniousLogo from '@keenious/libraries/assets/keenious-logo.svg';
import KeeniousPlusLogo from '@keenious/libraries/assets/keenious-plus-logo.svg';
import { Scrollable } from '@keenious/libraries/components/addon/Scrollable';
import { AlertMessages } from '@keenious/libraries/components/alertMessages/AlertMessages/AlertMessages';
import { useAuth } from '@keenious/libraries/hooks/features/useAuth';

import { Home } from '../../home/Home/Home';
import { TopBar } from '../TopBar/TopBar';

export function HomePage() {
  const { isPlusUser } = useAuth();

  return (
    <>
      <TopBar sx={{ justifyContent: 'center', ml: '0.5px', mt: '-4px' }}>
        {isPlusUser ? <KeeniousPlusLogo height={22} /> : <KeeniousLogo height={22} />}
      </TopBar>

      <Scrollable>
        <AlertMessages />

        <Home />
      </Scrollable>
    </>
  );
}
